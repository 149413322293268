import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/layout-components/Header/Header";
import HeaderMobile from "../components/layout-components/Header/HeaderMobile"; // Import HeaderMobile
import Footer from "../components/layout-components/Footer/Footer";
import { isMobile } from "react-device-detect"; // Import isMobile from react-device-detect

const MainLayout = () => {
  return (
    <div>
      {isMobile ? <HeaderMobile /> : <Header />} {/* Use HeaderMobile for mobile devices */}
      <main style={{ padding: "20px" }}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
