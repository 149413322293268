import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ProductContainer from "./ProductContainer";
import { Row, Col } from "antd";
import ProductFilters from "./ProductFilters";
import "../../../assets/css/app_view.css";

const ProductDisplay = (props) => {
  const [products, setProducts] = useState(props.products);

  const cashback = props.cashback;
  const discount = props.discount;
  const vendor_id = props.vendor_id;

  const fetchProductsFromChild = (elements) => {
    setProducts(elements);
  };

  useEffect(() => {
    setProducts(props.products);
  }, [props.products, cashback, discount]);

  return (
    <>
      <div className={isMobile ? "main-container-mobile" : "main-container"}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={4}>
            {!props.isSearch && ( // Conditionally render ProductFilters
              <ProductFilters
                vendor_id={vendor_id}
                fetchProductsFromChild={fetchProductsFromChild}
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={18} lg={20}>
            <section className="products">
              <ProductContainer
                products={products ?? []}
                cashback={cashback}
                discount={discount}
              />
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductDisplay;
