import React, { useState, useEffect } from "react";
import { Row, Col, Pagination } from "antd";
import { useLocation } from "react-router-dom";
import ProductDisplay from "../ProductDisplay";
import "assets/css/app_view.css";

const domain = process.env.REACT_APP_DOMAIN;

const Categories = () => {
  const location = useLocation();
  const urlsearchparams = new URLSearchParams(location.search);
  const primary = urlsearchparams.get("primary");
  const secondary = urlsearchparams.get("secondary");
  const tertiary = urlsearchparams.get("tertiary");

  console.log(primary, secondary, tertiary);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchProductsByCategory();
  }, [currentPage, pageSize, primary, secondary, tertiary]);

  const fetchProductsByCategory = async () => {
    try {
      let url = "";
      if (domain === "hlooms")
        url = `${process.env.REACT_APP_API_URL}api/v1/products/search/hlooms?`;
      else if (domain === "neonyte")
        url = `${process.env.REACT_APP_API_URL}api/v1/products/search?`;

      if (primary) url += `primary=${primary}&`;
      if (secondary) url += `secondary=${secondary}&`;
      if (tertiary) url += `tertiary=${tertiary}&`;
      url += `page=${currentPage}&size=${pageSize}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Error fetching products");
      }
      const data = await response.json();
      setProducts(data.products);
      setTotalItems(data.totalItems);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div className="main-container">
      <Row justify="center">
        <Col xs={24} md={20}>
          <h2>All Products</h2>
          <ProductDisplay products={products} />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Categories;
