import React from "react";
import { Row, Col, Card, Typography, Form, Input, Button, message } from "antd";
import axios from "axios"; // Import Axios
import "./ContactUs.css"; // Import CSS file for styling

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    console.log("Form values:", values);
    let url = `${process.env.REACT_APP_API_URL}api/v1/customer_care/add`;
    console.log("URL:", url);
    try {
      const response = await axios.post(url, values);
      message.success("Your message has been sent successfully!");
      form.resetFields(); // Clear the form
    } catch (error) {
      console.error("Error sending message:", error);
      message.error(
        "There was an error sending your message. Please try again."
      );
    }
  };

  return (
    <div className="contact-us-container">
      <Row justify="center" style={{ padding: "20px 0", marginTop: "90px" }}>
        <Col xs={24} md={20}>
          <Card className="contact-us-card">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Title level={2}>Contact Us</Title>
                <Paragraph>
                  We would love to hear from you! If you have any questions,
                  feedback, or inquiries, please feel free to reach out to us
                  using the form below. Our team is here to assist you and
                  ensure you have the best experience possible.
                </Paragraph>
                <Form layout="vertical" onFinish={handleSubmit} form={form}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input placeholder="Your Name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input placeholder="Your Email" />
                  </Form.Item>
                  <Form.Item
                    name="message"
                    label="Message"
                    rules={[
                      { required: true, message: "Please enter your message" },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Your Message" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} md={12}>
                <div className="contact-info">
                  <Title level={3}>Our Office</Title>
                  <Paragraph>
                    36 Emami Nest Flat No 302
                    <br />
                    8th Main 16th Cross Malleshwaram
                    <br />
                    Bengaluru, Karnataka 560003
                    <br />
                    Phone: +91 95133 33471
                    <br />
                    Email: info@neonyte.com
                  </Paragraph>
                  <Title level={3}>Business Hours</Title>
                  <Paragraph>
                    Monday - Friday: 9:00 AM - 6:00 PM
                    <br />
                    Saturday: 10:00 AM - 4:00 PM
                    <br />
                    Sunday: Closed
                  </Paragraph>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
