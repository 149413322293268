import { react, useEffect, useState } from "react";
import { Row, Col, Card, Button, Select, Radio, Collapse, Slider, Checkbox, List, message } from "antd"; // Import List and message
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons"; // Import DeleteOutlined and CheckOutlined icons
import axios from "axios"; // Add Axios import
import SearchBar from "../Header/SearchBar";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate

const { Panel } = Collapse;
const { Option } = Select; // Destructure Option from Select
const domain = process.env.REACT_APP_DOMAIN;

const ProductFilters = ({ vendor_id, fetchProductsFromChild }) => {
  const [priceBand, setPriceBand] = useState([0, 200000]); // Change to array for slider
  const [brandList, setBrandList] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null); // Change to single value
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [showMoreBrands, setShowMoreBrands] = useState(false);
  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);

  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate

  const urlSearchParams = new URLSearchParams(location.search);
  const primary = urlSearchParams.get("primary");
  const secondary = urlSearchParams.get("secondary");
  const tertiary = urlSearchParams.get("tertiary");
  const minPrice = urlSearchParams.get("minPrice");
  const maxPrice = urlSearchParams.get("maxPrice");
  const sort = urlSearchParams.get("sort");
  const brand = urlSearchParams.get("brand");
  const rating = urlSearchParams.get("rating");
  const search = urlSearchParams.get("search");

  const buildQueryParams = () => {
    const params = new URLSearchParams();
    if (primary) params.append("primary", primary);
    if (secondary) params.append("secondary", secondary);
    if (tertiary) params.append("tertiary", tertiary);
    if (minPrice) params.append("minPrice", minPrice);
    if (maxPrice) params.append("maxPrice", maxPrice);
    if (sort) params.append("sort", sort);
    if (brand) params.append("brand", brand);
    if (rating) params.append("rating", rating);
    if (search) params.append("search", search);
    return params.toString();
  };

  const fetchProducts = async () => {
    const queryParams = buildQueryParams();
    console.log("queryParams", queryParams);
    let url = "";
    if (domain === "hlooms")
      url = `${process.env.REACT_APP_API_URL}api/v1/products/search/hlooms?${queryParams}`;
    else if (domain === "neonyte")
      url = `${process.env.REACT_APP_API_URL}api/v1/products/search?${queryParams}`;

    try {
      const response = await axios.get(url);
      setProducts(response.data.products);
      setTotalItems(response.data.totalItems);
      fetchProductsFromChild(response.data.products);
    } catch (error) {
      console.error("Error retrieving products", error);
    }
  };

  const onPriceChange = (e) => {
    const value = e.target.value;
    setSelectedPrice(value);
    urlSearchParams.set("sort", value); // Update urlSearchParams
    navigate({ search: urlSearchParams.toString() }); // Update URL
    fetchProducts();
  };

  const onSliderDuringChange = (value) => {
    console.log("Slider value during change:", value);
    setPriceBand(value);
    urlSearchParams.set("minPrice", value[0]);
    urlSearchParams.set("maxPrice", value[1]);
    navigate({ search: urlSearchParams.toString() }); // Update URL
    fetchProducts();
  };

  const onSliderChange = (value) => {
    fetchProducts();
  };

  const onBrandCheckboxChange = (checkedValues) => {
    console.log("checkedValues", checkedValues);
    setSelectedBrands(checkedValues);
    urlSearchParams.set("brand", checkedValues.join(","));
    navigate({ search: urlSearchParams.toString() }); // Update URL
    fetchProducts();
  };

  const onRatingCheckboxChange = (checkedValues) => {
    setSelectedRatings(checkedValues);
    urlSearchParams.set("rating", checkedValues.join(","));
    navigate({ search: urlSearchParams.toString() }); // Update URL
    fetchProducts();
  };

  const fetchBrands = () => {
    let url = "";
    if (domain === "hlooms") {
      url = `${process.env.REACT_APP_API_URL}api/v1/products/fetchBrands/hlooms`;
    }
    else if (domain === "neonyte") {
      url = `${process.env.REACT_APP_API_URL}api/v1/products/fetchBrands`;
    }
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error("Error retrieving brands");
        }
        return response.json();
      })
      .then((brandList) => {
        setBrandList(brandList.map((brand) => brand.brand)); // Ensure brandList is an array of strings
      })
      .catch((err) => {
        throw Error(err.message);
      });
  };

  const fetchAllProducts = async () => {
    let url = "";
    if (domain === "hlooms") {
      url =
        process.env.REACT_APP_API_URL +
        `api/v1/products/all/hlooms?page=${currentPage}&size=${pageSize}`;
    } else
      url =
        process.env.REACT_APP_API_URL +
        `api/v1/products/all?page=${currentPage}&size=${pageSize}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error("Error retrieving products");
        }
        return response.json();
      })
      .then((allData) => {
        console.log(allData["products"]);
        fetchProductsFromChild(allData["products"]);
        setTotalItems(allData["totalItems"]);
      })
      .catch((err) => {
        throw Error(err.message);
      });
  };

  const fetchProductsByVendorID = async () => {
    let url = "";
    if (domain === "hlooms") {
      url =
        process.env.REACT_APP_API_URL +
        `api/v1/products/vendor/hlooms?vendor_id=${vendor_id}&page=${currentPage}&size=${pageSize}`;
    } else
      url =
        process.env.REACT_APP_API_URL +
        `api/v1/products/vendor?vendor_id=${vendor_id}&page=${currentPage}&size=${pageSize}`;
    console.log("url", url);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error("Error retrieving products");
        }
        return response.json();
      })
      .then((allData) => {
        console.log(allData["products"]);
        fetchProductsFromChild(allData["products"]);
        setTotalItems(allData["totalItems"]);
      })
      .catch((err) => {
        throw Error(err.message);
      });
  };

  const toggleShowMoreBrands = () => {
    setShowMoreBrands(!showMoreBrands);
  };

  const toggleShowMoreCategories = () => {
    setShowMoreCategories(!showMoreCategories);
  };

  const resetFilters = () => {
    setSelectedBrands([]);
    setSelectedPrice(null); // Reset selected price
    setSelectedCategories([]);
    setSelectedRatings([]);
    setPriceBand([0, 200000]); // Reset price slider
    urlSearchParams.delete("primary");
    urlSearchParams.delete("secondary");
    urlSearchParams.delete("tertiary");
    urlSearchParams.delete("minPrice");
    urlSearchParams.delete("maxPrice");
    urlSearchParams.delete("sort");
    urlSearchParams.delete("brand");
    urlSearchParams.delete("rating");
    urlSearchParams.delete("search");
    navigate({ search: urlSearchParams.toString() }); // Update URL
    fetchAllProducts();
  };

  const handleDeleteFilter = (filterType, value) => {
    switch (filterType) {
      case "brand":
        setSelectedBrands(selectedBrands.filter((brand) => brand !== value));
        urlSearchParams.set("brand", selectedBrands.filter((brand) => brand !== value).join(","));
        break;
      case "rating":
        setSelectedRatings(selectedRatings.filter((rating) => rating !== value));
        urlSearchParams.set("rating", selectedRatings.filter((rating) => rating !== value).join(","));
        break;
      case "price":
        setPriceBand([0, 200000]);
        urlSearchParams.delete("minPrice");
        urlSearchParams.delete("maxPrice");
        break;
      default:
        break;
    }
    navigate({ search: urlSearchParams.toString() }); // Update URL
    fetchProducts();
  };

  const saveFilters = () => {
    const queryParams = buildQueryParams();
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters")) || [];
    savedFilters.push(queryParams);
    localStorage.setItem("savedFilters", JSON.stringify(savedFilters));
    setSavedFilters(savedFilters);
    message.success("Filters saved successfully!");
  };

  const applySavedFilter = (filter) => {
    navigate({ search: filter });
    fetchProducts();
  };

  const formatFilter = (filter) => {
    return filter.split("&").map((param) => {
      const [key, value] = param.split("=");
      return `${key.replace(/([a-z])([A-Z])/g, '$1 $2')}: ${decodeURIComponent(value)}`;
    }).join(", ");
  };

  const parseFilter = (filter) => {
    return filter.split(", ").map((param) => {
      const [key, value] = param.split(": ");
      return `${key.replace(/ /g, '')}=${encodeURIComponent(value)}`;
    }).join("&");
  };

  useEffect(() => {
    console.log("vendor_id", vendor_id);
    if (!vendor_id) fetchAllProducts();
    else {
      fetchProductsByVendorID();
    }
    fetchBrands();
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters")) || [];
    setSavedFilters(savedFilters);
  }, []);

  return (
    <>
      <Card
        style={{
          backgroundColor: "#fdece8",
          width: "100%",
        }}
      >
        <Row gutter={16} justify="start">
          <Col xs={24} sm={24} md={24} lg={24}> {/* Set Col to full width */}
            <Button
              type="primary"
              onClick={resetFilters}
              style={{ marginBottom: "20px", marginRight: "10px" }} // Add marginRight
            >
              Reset Filters
            </Button>
            <Button
              type="default"
              onClick={saveFilters}
              style={{ marginBottom: "20px" }}
            >
              Save Filters
            </Button>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Brand" key="1">
                <Checkbox.Group
                  value={selectedBrands} // Bind selectedBrands to the Checkbox.Group
                  options={
                    showMoreBrands
                      ? brandList.map((brand) => ({
                        label: brand,
                        value: brand,
                      }))
                      : brandList.slice(0, 10).map((brand) => ({
                        label: brand,
                        value: brand,
                      }))
                  }
                  onChange={onBrandCheckboxChange}
                  style={{
                    display: "block",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }} // Add marginLeft
                />
                {brandList.length > 10 && (
                  <Button type="link" onClick={toggleShowMoreBrands}>
                    {showMoreBrands ? "Show Less" : "Show More"}
                  </Button>
                )}
              </Panel>
              <Panel header="Rating" key="2">
                <Checkbox.Group
                  value={selectedRatings} // Bind selectedRatings to the Checkbox.Group
                  options={[
                    { label: "★★★★★", value: 5 },
                    { label: "★★★★", value: 4 },
                    { label: "★★★", value: 3 },
                    { label: "★★", value: 2 },
                    { label: "★", value: 1 },
                  ]}
                  onChange={onRatingCheckboxChange}
                  style={{
                    display: "block",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }} // Add marginLeft
                />
              </Panel>
              <Panel header="Price" key="3">
                <Radio.Group
                  value={selectedPrice} // Bind selectedPrice to the Radio.Group
                  onChange={onPriceChange}
                  style={{
                    display: "block",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }} // Add marginLeft
                >
                  <Radio value="increasing">Increasing</Radio>
                  <Radio value="decreasing">Decreasing</Radio>
                </Radio.Group>
                <div style={{ padding: "0 10px" }}> {/* Add padding for slider */}
                  <Slider
                    range
                    min={0}
                    max={200000}
                    step={100}
                    value={priceBand}
                    onChange={onSliderDuringChange}
                    onAfterChange={onSliderChange} // Call onSliderAfterChange when slider is fully dragged
                    style={{
                      marginBottom: "20px",
                    }} // Add marginBottom
                  />
                </div>
              </Panel>
            </Collapse>
            <List
              header={<div>Selected Filters</div>}
              bordered
              dataSource={[
                ...selectedBrands.map((brand) => ({ type: "brand", value: brand })),
                ...selectedRatings.map((rating) => ({ type: "rating", value: rating })),
                { type: "price", value: priceBand }
              ]}
              renderItem={item => (
                <List.Item
                  style={{ padding: "8px 16px" }} // Reduce padding
                  actions={[<DeleteOutlined onClick={() => handleDeleteFilter(item.type, item.value)} />]} // Replace button with icon
                >
                  {item.type === "price" ? `Price: ${priceBand[0]} - ${priceBand[1]}` : `${item.type}: ${item.value}`}
                </List.Item>
              )}
            />
            <List
              header={<div>Saved Filters</div>}
              bordered
              dataSource={savedFilters}
              renderItem={(filter, index) => (
                <List.Item
                  style={{ padding: "8px 16px" }} // Reduce padding
                >
                  <>
                    <Row justify="center">
                      <Col xs={24} md={24}>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Saved Filters"
                          value={formatFilter(filter).split(", ")}
                          onDeselect={(value) => {
                            const updatedFilters = savedFilters.filter((_, i) => i !== index);
                            localStorage.setItem("savedFilters", JSON.stringify(updatedFilters));
                            setSavedFilters(updatedFilters);
                          }}
                        >
                          {formatFilter(filter).split(", ").map((param, i) => (
                            <Option key={i} value={param}>
                              {param}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col xs={24} md={24}>
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            const updatedFilters = savedFilters.filter((_, i) => i !== index);
                            localStorage.setItem("savedFilters", JSON.stringify(updatedFilters));
                            setSavedFilters(updatedFilters);
                          }}
                          style={{ marginRight: "8px" }} // Add marginRight
                        />
                        <Button
                          type="text"
                          icon={<CheckOutlined />}
                          onClick={() => applySavedFilter(parseFilter(filter))}
                        />
                      </Col>
                    </Row>
                  </>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ProductFilters;
