import React from "react";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import { Row, Col } from "antd";

const ProductAttributes = ({ attributes }) => {
  console.log("attributes", attributes);
  return (
    <Row gutter={16}>
      {attributes.map((attribute, index) => (
        <Col xs={24} sm={12} md={6} lg={3} key={index}>
          <Card style={{ padding: "20px", border: "none" }}>
            <Typography variant="h6" style={{ color: "#E75627" }}>
              {attribute.feature_name}
            </Typography>
            <Typography variant="body2" style={{ color: "#E75627" }}>
              {attribute.feature_value}
            </Typography>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

ProductAttributes.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductAttributes;
