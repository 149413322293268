import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VendorPage from "../views/vendor/VendorPage";
import ProductPage from "views/product/ProductPage";
import ReturnPolicy from "components/layout-components/Markdown/ReturnPolicy";
import SearchResults from "components/layout-components/Search/SearchResults";
import ThankYouPage from "views/thank-you/ThankYouPage";

const PublicRoutes = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Categories />} />
        <Route path="/vendor/:vendor_id" element={<VendorPage />} />
        <Route path="/product/:product_id" element={<ProductPage />} />
        <Route path="/return_policy" element={<ReturnPolicy />} />
        <Route
          path="/search"
          element={
            <SearchResults

            />
          }
        />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
