import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  count: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.items.push(action.payload);
      state.count += 1;
    },
    removeFromCart: (state, action) => {
      console.log("action.payload:", action.payload);
      console.log("state.items:", state);
      state.items = state.items.filter((item) => item.cart_id !== action.payload.cart_id);
      state.count -= 1;
    },
    setCartCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { addToCart, removeFromCart, setCartCount } = cartSlice.actions;
export default cartSlice.reducer;
