import React from "react";
import { useParams } from "react-router-dom";
import VendorCard from "../../components/layout-components/VendorCard";
import ProductDisplay from "components/layout-components/ProductDisplay";

const VendorPage = () => {
  const { vendor_id } = useParams();
  console.log("vendor_id", vendor_id);
  return (
    <div className="main-container">
      <VendorCard vendor_id={vendor_id} />
      <ProductDisplay vendor_id={vendor_id} />
    </div>
  );
};

export default VendorPage;
