import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import TermsAndConditions from "./TermsConditions.md";
import { Row, Col, Card } from "antd";

const TermsConditionsPolicy = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(TermsAndConditions)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => {
        console.error("Error loading markdown file:", error);
      });
  }, []);

  return (
    <Row justify="center" style={{ padding: "20px 0", marginTop: "90px" }}>
      <Col xs={24} md={20}>
        <Card style={{ padding: "20px" }}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </Card>
      </Col>
    </Row>
  );
};

export default TermsConditionsPolicy;
