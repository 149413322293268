import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import VendorPage from "views/vendor/VendorPage";

export const publicRoutes = [
  {
    key: "vendor",
    path: `${AUTH_PREFIX_PATH}/vendor/:vendor_id`,
    component: VendorPage,
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.orders",
    path: `${APP_PREFIX_PATH}/dashboards/orders`,
    component: React.lazy(() => import("views/app-views/dashboards/orders")),
  },
  {
    key: "apps.userconsent",
    path: `${APP_PREFIX_PATH}/apps/userconsent`,
    component: React.lazy(() => import("views/app-views/apps/userconsent")),
  },
  {
    key: "apps.demograph",
    path: `${APP_PREFIX_PATH}/apps/demograph`,
    component: React.lazy(() => import("views/app-views/apps/demograph")),
  },
  {
    key: "apps.interest",
    path: `${APP_PREFIX_PATH}/apps/interest`,
    component: React.lazy(() => import("views/app-views/apps/interest")),
  },
  {
    key: "discovery.ai",
    path: `${APP_PREFIX_PATH}/discovery/ai`,
    component: React.lazy(() => import("views/app-views/discovery/ai")),
  },
];
