import React from "react";
import { Card, Button } from "antd";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleContinueShopping = () => {
    navigate("/");
  };

  return (
    <Card style={{ textAlign: "center", marginTop: "90px" }}>
      <h1>Thank You for Your Order!</h1>
      <p>Your order has been placed successfully.</p>
      <Button
        type="primary"
        style={{ backgroundColor: "#E75627", color: "white" }}
        onClick={handleContinueShopping}
      >
        Continue Shopping
      </Button>
    </Card>
  );
};

export default ThankYouPage;
