import React from "react";
import { Row, Col, Card, Typography } from "antd";
import "./AboutUs.css"; // Import CSS file for styling

const { Title, Paragraph } = Typography;
const domain = process.env.REACT_APP_DOMAIN;

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Row justify="center" style={{ padding: "20px 0", marginTop: "90px" }}>
        <Col xs={24} md={20}>
          <Card className="about-us-card">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <img
                  src={`${process.env.REACT_APP_FILESERVER}/common/images/retail.jpeg`}
                  alt="Retail"
                  className="retail-image"
                />
              </Col>
              <Col xs={24} md={12}>
                <Title level={2}>About Us</Title>
                <Paragraph>
                  Welcome to our retail store! We are dedicated to providing you
                  with the best shopping experience possible. Our mission is to
                  offer high-quality products at affordable prices, ensuring
                  customer satisfaction every step of the way. {domain}.com is a
                  brand of Simulate Intelligence Private limited, a web3 and AI
                  platform company located in bengaluru, India. You can visit
                  https://simtel.ai for more details.
                </Paragraph>
                <Paragraph>
                  Our team is passionate about retail and committed to
                  delivering exceptional service. We carefully curate our
                  product selection to meet the diverse needs of our customers.
                  Whether you're looking for the latest fashion trends,
                  electronics, home goods, or beauty products, we have something
                  for everyone.
                </Paragraph>
                <Paragraph>
                  Thank you for choosing us as your preferred shopping
                  destination. We look forward to serving you and making your
                  shopping experience enjoyable and memorable.
                </Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
