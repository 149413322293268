import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Layout from "../layouts/MainLayout";
import Home from "../components/layout-components/Home/Home";
import SearchResults from "../components/layout-components/Search/SearchResults";
import Categories from "../components/layout-components/Categories/Categories";
const PublicRoute = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/category" element={<Categories />} />

        <Route path="*" element={<Outlet />} />
      </Routes>
    </Layout>
  );
};

export default PublicRoute;
