import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import cart from "./slices/cartSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    cart,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
