import React from "react";
import { Row, Col, Tabs } from "antd";
import SwiperComponent from "../Swiper";
import ProductPage from "./ProductPage";
import "assets/css/app_view.css";
const { TabPane } = Tabs;

const Home = () => {
  return (
    <Row className="slide-container">
      <Col xs={24} md={20}>
        <SwiperComponent />
        {/* <Tabs defaultActiveKey="1">
          <TabPane tab="Cashback" key="1">
            <ProductPage cashback={true} />
          </TabPane>
          <TabPane tab="Festive Sale" key="2">
            <ProductPage discount={true} />
          </TabPane>
          <TabPane tab="New Products" key="3">
            <ProductPage newProducts={true} />
          </TabPane>
        </Tabs> */}
        <ProductPage newProducts={true} />
      </Col>
    </Row>
  );
};

export default Home;
