import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import ProductCard from "../ProductDisplay/ProductCard";

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []);

  const handleFavoriteToggle = (productId, isFavorite) => {
    if (!isFavorite) {
      setFavorites((prevFavorites) =>
        prevFavorites.filter((product) => product.product_id !== productId)
      );
    }
  };

  const addFavorite = (product) => {
    setFavorites((prevFavorites) => {
      const isDuplicate = prevFavorites.some(
        (fav) => fav.product_id === product.product_id
      );
      if (!isDuplicate) {
        return [...prevFavorites, product];
      }
      return prevFavorites;
    });
  };

  return (
    <Row justify="center" style={{ padding: "20px 0", marginTop: "90px" }}>
      <Col xs={24} md={20}>
        <h2>My Favorites</h2>
        <Row gutter={[16, 16]}>
          {favorites.map((product, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <ProductCard
                input={product}
                onFavoriteToggle={handleFavoriteToggle}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Favorites;
