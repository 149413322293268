import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Drawer,
    Badge,
    Divider, // Import Divider
} from "antd";
import {
    ShoppingCartOutlined,
    HeartFilled,
    SearchOutlined,
    UserOutlined,
    LoginOutlined, // Import UserOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import hlooms from "./hlooms.json";
import neonyte from "./neonyte.json";
import "./Header.css";
import SearchBar from "./SearchBar";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { signInSuccess } from "store/slices/authSlice";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { v4 as uuidv4 } from "uuid"; // Import UUID
import axios from "axios"; // Import axios
import { setCartCount } from "store/slices/cartSlice";
import store from "store"; // Import store
import { AUTH_TOKEN, PARSED_TOKEN } from "constants/AuthConstant"; // Import AUTH_TOKEN and PARSED_TOKEN
import MenuContent from "../MenuContent";

const domain = process.env.REACT_APP_DOMAIN;

const HeaderMobile = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [deliverToZipcode, setDeliverToZipcode] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showSearchBar, setShowSearchBar] = useState(false); // State to control search bar visibility
    const { keycloak, initialized } = useKeycloak();
    const cartCount = useSelector((state) => state.cart?.count || 0); // Get cart count from Redux state with default value
    const dispatch = useDispatch();

    useEffect(() => {
        if (keycloak.authenticated) {
            console.log("Sending token", keycloak.token);
            dispatch(signInSuccess(keycloak.token));
        } else {
            let dummy_username = localStorage.getItem("dummy_username");
            if (!dummy_username) {
                dummy_username = "Guest" + uuidv4().slice(-12).toUpperCase();
                localStorage.setItem("dummy_username", dummy_username);
            }
        }
    }, [keycloak, dispatch]);

    const handleSignIn = () => {
        const dummy_username = localStorage.getItem("dummy_username");
        const username = keycloak.tokenParsed?.preferred_username;
        if (
            keycloak.authenticated &&
            dummy_username &&
            dummy_username !== username
        ) {
            axios
                .post(process.env.REACT_APP_API_URL + "api/v1/cart/replaceUsername", {
                    old_username: dummy_username,
                    new_username: username,
                })
                .then(() => {
                    localStorage.removeItem("dummy_username");
                })
                .catch((error) => {
                    console.error("Error updating username in the backend:", error);
                });
        }
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            handleSignIn();
            // Fetch cart count on initial load
            const fetchCartCount = async () => {
                const username = keycloak.tokenParsed?.preferred_username;
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}api/v1/cart/user/${username}`
                );
                console.log("Cart count response:", response.data.count);
                dispatch(setCartCount(response.data.count));
            };
            fetchCartCount();
        }
    }, [keycloak.authenticated, dispatch]);

    useEffect(() => {
        // This effect will run whenever cartCount changes
        console.log("Cart count updated:", cartCount);
    }, [dispatch, cartCount]);

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            console.log("state:", state);
            setCartCount(state.cart ? state.cart?.count : 0);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleSignInClick = () => {
        keycloak.login();
    };

    const handleLogout = () => {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(PARSED_TOKEN);
        keycloak.clearToken();
        const dummy_usename = "Guest" + uuidv4().slice(-12).toUpperCase();
        localStorage.setItem("dummy_username", dummy_usename);
        keycloak.logout();
    };

    const showDrawer = () => {
        setIsDrawerOpen(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    const toggleSearchBar = () => {
        setShowSearchBar(!showSearchBar);
    };

    let menuData = {};
    if (domain === "hlooms") menuData = hlooms;
    else if (domain === "neonyte") menuData = neonyte;

    return (
        <div className="header-container">
            <Row gutter={16}>
                <Col xs={12} style={{ textAlign: "left" }}>
                    <Link to="/">
                        <img
                            src={`https://fileserver.neonyte.com/${domain}/logo/logo.jpg`}
                            alt="Company Logo"
                            className="header-logo"
                        />
                    </Link>
                </Col>
                {!showSearchBar && (
                    <Col xs={3} style={{ textAlign: "center" }}>
                        <Button type="default" icon={<SearchOutlined />} onClick={toggleSearchBar} className="transparent-button no-border" />
                    </Col>)}
                <Col xs={3} style={{ textAlign: "center" }}>
                    <Button type="default" icon={<HeartFilled />} className="transparent-button no-border" />
                </Col>

                <Col xs={3} style={{ textAlign: "center" }}>
                    <Badge count={cartCount} offset={[0, 0]} showZero>
                        <Button type="default" icon={<ShoppingCartOutlined />} className="transparent-button no-border" />
                    </Badge>
                </Col>
                <Col xs={3} style={{ textAlign: "center" }}>
                    <Button
                        type="default"
                        icon={<LoginOutlined />}
                        className="header-login-button"
                        onClick={handleSignInClick}
                    />
                </Col>
            </Row>
            {showSearchBar && (
                <>
                    <Divider /> {/* Add Divider */}
                    <Row gutter={16} align="middle">
                        <Col xs={24}>
                            <SearchBar setSearchResults={setSearchResults} />
                        </Col>
                    </Row>
                </>
            )}
            {keycloak.authenticated && (
                <Drawer
                    title="Menu"
                    placement="left"
                    onClose={closeDrawer}
                    visible={isDrawerOpen}
                >
                    <MenuContent type={NAV_TYPE_SIDE} /> {/* Add MenuContent component */}
                </Drawer>
            )}
        </div>
    );
};

export default HeaderMobile;
