// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    background-color: #fdece8;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.header-logo {
    height: 40px;
}

.header-button {
    background-color: #e75627;
    border-color: #e75627;
    margin-right: 10px;
}

.header-button:hover {
    background-color: #fdece8;
    color: #fdece8;
    border-color: #fdece8;
    transition: 0.7s;
}

.header-search {
    hover-bg: #e75627;
    hover-border-color: #e75627;
    color: #fff;
}

.header-icon {
    font-size: 18px;
    margin: 0 10px;
    color: #e75627;
}

.header-login-button {
    background-color: #e75627;
    border-color: #e75627;
    color: #fff;
    margin-left: 10px;
}

.header-menu {
    border-bottom: none;
}

.header-menu .ant-menu-item,
.header-menu .ant-menu-submenu-title {
    margin-right: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.favorite-icon {
    font-size: 24px;
    color: #e75627;
    cursor: pointer;
}

.transparent-button {
    opacity: 0.9;
    background-color: transparent;
    outline: none;
}

.no-border {
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/layout-components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,eAAe;IACf,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header-container {\n    background-color: #fdece8;\n    padding: 10px 20px;\n    position: fixed;\n    top: 0;\n    width: 100%;\n    z-index: 1000;\n}\n\n.header-logo {\n    height: 40px;\n}\n\n.header-button {\n    background-color: #e75627;\n    border-color: #e75627;\n    margin-right: 10px;\n}\n\n.header-button:hover {\n    background-color: #fdece8;\n    color: #fdece8;\n    border-color: #fdece8;\n    transition: 0.7s;\n}\n\n.header-search {\n    hover-bg: #e75627;\n    hover-border-color: #e75627;\n    color: #fff;\n}\n\n.header-icon {\n    font-size: 18px;\n    margin: 0 10px;\n    color: #e75627;\n}\n\n.header-login-button {\n    background-color: #e75627;\n    border-color: #e75627;\n    color: #fff;\n    margin-left: 10px;\n}\n\n.header-menu {\n    border-bottom: none;\n}\n\n.header-menu .ant-menu-item,\n.header-menu .ant-menu-submenu-title {\n    margin-right: 20px;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.favorite-icon {\n    font-size: 24px;\n    color: #e75627;\n    cursor: pointer;\n}\n\n.transparent-button {\n    opacity: 0.9;\n    background-color: transparent;\n    outline: none;\n}\n\n.no-border {\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
