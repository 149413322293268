import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Select,
  Input,
  Button,
  Badge,
  Row,
  Col,
  Modal,
  Divider,
  Form,
  InputNumber,
  message,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import Flex from "components/shared-components/Flex";
import AddressList from "./AddressList";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import utils from "utils";
import axios from "axios";
import moment from "moment";
import { PARSED_TOKEN } from "constants/AuthConstant";
import { v4 as uuidv4 } from "uuid";
import keycloak from "Keycloak";
import { useDispatch } from "react-redux";
import { setCartCount, removeFromCart } from "store/slices/cartSlice"; // Import removeFromCart reducer

const { Option } = Select;
const rupee = String.fromCharCode("\u20B9");

const formatCurrency = (value) => {
  return value
    ? Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value)
    : 0;
};
const light_background = "#fdece8";
const brand_color = "#E75627";

const { Meta } = Card;

const initializeRazorpay = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };

    document.body.appendChild(script);
  });
};

const Cart = () => {
  const parsed_token = JSON.parse(localStorage.getItem(PARSED_TOKEN));
  const username = parsed_token
    ? parsed_token.preferred_username
    : localStorage.getItem("dummy_username");

  console.log("dummy_username", localStorage.getItem("dummy_username"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [postGSTAmount, setPostGSTAmount] = useState(0.0);
  const [totalShippingCost, setTotalShippingCost] = useState(0.0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0.0);


  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [shippingZipcode, setShippingZipcode] = useState();

  const [disableCheckout, setDisableCheckout] = useState(true);
  const [disableCashOnDelivery, setDisableCashOnDelivery] = useState(true);
  const [invoice, setInvoice] = useState();

  const fetchCartCount = async () => {
    const url = process.env.REACT_APP_API_URL + `api/v1/cart/user/${username}`;
    try {
      const response = await axios.get(url);
      dispatch(setCartCount(response.data.count));
    } catch (error) {
      console.log("Error fetching cart count:", error);
    }
  };

  const fetchCart = async () => {
    const url = process.env.REACT_APP_API_URL + `api/v1/cart/user/${username}`;

    // GST
    var post_gst_amount = 0.0;
    var pre_gst_amount = 0.0;
    var gst_amount = 0.0;
    var gst = 0.0;
    var shipping_cost = 0.0;

    let total_amount = 0.0;
    let total_gst = 0.0;
    try {
      let product_list = [];
      const products = [];
      await axios
        .get(url)
        .then((response) => {
          console.log("rows:", response.data.rows);
          product_list = response.data.rows;
        })
        .catch((error) => {
          console.log("error:", error);
        });

      for (let item of product_list) {
        console.log("item::", item);
        let price = parseFloat(item.offer_price);
        let quantity = parseInt(item.quantity);
        let cgst = parseFloat(item.cgst) ?? 9;
        let sgst = parseFloat(item.sgst) ?? 9;
        pre_gst_amount = 0.0;
        gst_amount = 0.0;
        shipping_cost = 0.0;

        post_gst_amount = price * quantity;
        pre_gst_amount = price * (100 / (cgst + sgst + 100)) * quantity;
        gst = price * quantity - price * (100 / (cgst + sgst + 100)) * quantity;

        total_amount += post_gst_amount;
        total_gst += gst;
        console.log("name", item.product_name);
        console.log("price", price);
        console.log("quantity", quantity);
        console.log("pre_gst_amount", pre_gst_amount);
        console.log("gst_amount", gst);
        console.log("post_gst_amount", post_gst_amount);
        console.log("total_amount", total_amount);

        item["gst"] = gst;
        item["pre_gst"] = pre_gst_amount;
        item["sub_total"] = post_gst_amount;
        item["price"] = post_gst_amount;
        let calc_shipment_fees = 300;
        console.log("vendor_shipment", item.vendor_shipment);
        if (item.vendor_shipment === "YES") {
          shipping_cost += item.shipment_fees
            ? parseFloat(item.shipment_fees) * quantity
            : calc_shipment_fees * quantity;
          item["shipment_cost"] = item.shipment_fees
            ? parseFloat(item.shipment_fees) * quantity
            : calc_shipment_fees * quantity;
        } else {
          //calculate with shiprocket
          // for now,
          console.log("shippingZipcode", shippingZipcode);
          if (shippingZipcode) {
            const output = await estimateShippingCost(item);
            console.log("output:", output);
            shipping_cost += output.freight_charge
              ? parseFloat(output.freight_charge) * quantity * 1.18
              : calc_shipment_fees * quantity;
            item["shipment_cost"] = output.freight_charge
              ? parseFloat(output.freight_charge) * quantity * 1.18
              : calc_shipment_fees * quantity;
          }
          //
          else {
            shipping_cost += 0;
            item["shipment_cost"] = 0;
          }
        }
        products.push(item);
      }
      console.log("products", products);
      setPostGSTAmount(total_amount);
      setTotalShippingCost(shipping_cost);
      setTotalPayableAmount(total_amount + shipping_cost);
      setLineItems(products);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const estimateShippingCost = async (item) => {
    console.log("shipping item", item);
    const shippingAddress = JSON.parse(
      localStorage.getItem("shippingAddress")
    )[0];
    const params_data = {
      params: {
        pickup_location: item.pickup_location,
        delivery_postcode: shippingAddress.zipcode,
        weight: item.shipment_weight,
        length: item.shipment_length,
        breadth: item.shipment_width,
        height: item.shipment_height,
      },
    };
    console.log("shipping input", params_data);
    const url = process.env.REACT_APP_API_URL + `api/v1/cart/shippingEstimate`;
    let output = {};
    await axios
      .get(url, params_data)
      .then((result) => {
        console.log("result::", result.data);
        output = result.data;
      })
      .catch((error) => {
        console.log("error fetching cost", error);
      });
    return output;
  };

  const handlePayment = async () => {
    const res = await initializeRazorpay();
    const ref_order_id = uuidv4(); // Main id for all refernce
    const ref_order_dt = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log("Total amount in the cart: ", postGSTAmount.toFixed(2));

    const parsed = JSON.parse(localStorage.getItem(PARSED_TOKEN));

    const shippingAddress = JSON.parse(
      localStorage.getItem("shippingAddress")
    )[0];
    console.log("Handling payment", shippingAddress);
    var NAME = "NA";
    var EMAIL = "NA";
    if (parsed) {
      var NAME = parsed.name;
      var EMAIL = parsed.email;
    }
    if (!res) {
      message.error(
        "Unable to load Payment Gateway. Please check your internet Connection"
      );
      return;
    }

    createInvoice(lineItems);

    const razopay_amount = (totalPayableAmount * 100).toFixed(0);
    const currency = "INR";
    // creating a new order
    const invoice_input = {
      amount: razopay_amount,
      currency: currency,
      receipt: ref_order_id,
      notes: {
        customer: NAME,
        email: EMAIL,
      },
    };
    console.log("create invoice", invoice_input);
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "api/v1/payment/createInvoice",
      invoice_input
    );
    console.log("order created", result);

    if (!result) {
      message.error("Server error..");
      return;
    }

    // Getting the order details back
    const invoice_json = result.data;
    console.log("invoice_json", invoice_json);
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: razopay_amount,
      currency: currency,
      name: "neonyte.com",
      description: "Product Purchase",
      image: "https://fileserver.neonyte.com/images/logo/neonyte_logo.png",
      order_id: invoice_json.id,

      handler: async function (response) {
        console.log("response", response);
        const data = {
          orderCreationId: invoice_json.id, // Order ID created to verify digest with signature
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          orderId: ref_order_id,
        };

        const result = await axios.post(
          process.env.REACT_APP_API_URL + "api/v1/payment/verifyPayment",
          data
        );

        var internal_order = {};
        var loyalty_rewards = {};
        var cashback = {};

        if (result.data.status === "success") {
          let order_items = [];
          let promises = [];

          lineItems.forEach((item) => {
            console.log("item", item);
            const lineitem = {
              name: item.product_name,
              sku: item.product_id + (item.variant_id ?? ""),
              units: item.quantity,
              selling_price: item.offer_price,
              discount: item.discount,
              tax: parseInt(item.cgst) + parseInt(item.sgst),
              hsn: item.hsn_code,
            };
            order_items.push(lineitem);

            const shipment_order_id =
              moment().format("YYYY-MM-DD") +
              "-" +
              ("" + Math.random()).substring(2, 8); // order_id for shipment

            const order = {
              order_id: shipment_order_id,
              order_date: ref_order_dt,
              pickup_location: item.pickup_location,
              channel_id: "5024240",
              comment: "razorpayOrderId: " + data.razorpayOrderId,
              billing_customer_name: shippingAddress.first_name,
              billing_last_name: shippingAddress.last_name,
              billing_address: shippingAddress.line1_address,
              billing_address_2: shippingAddress.line2_address,
              billing_city: shippingAddress.city,
              billing_pincode: shippingAddress.zipcode,
              billing_state: shippingAddress.state,
              billing_country: "India",
              billing_email: shippingAddress.email,
              billing_phone: shippingAddress.phone,
              shipping_is_billing: "1",
              shipping_customer_name: shippingAddress.first_name,
              shipping_last_name: shippingAddress.last_name,
              shipping_address: shippingAddress.line1_address,
              shipping_address_2: shippingAddress.line2_address,
              shipping_city: shippingAddress.city,
              shipping_pincode: shippingAddress.zipcode,
              shipping_country: "India",
              shipping_state: shippingAddress.state,
              shipping_email: shippingAddress.email,
              shipping_phone: shippingAddress.phone,
              order_items: [lineitem],
              payment_method: "Prepaid", // COD and PREPAID
              shipping_charges: item.shipment_cost,
              giftwrap_charges: 0,
              transaction_charges: 0,
              total_discount: item.discount,
              sub_total: item.offer_price,
              length: item.shipment_length,
              breadth: item.shipment_width,
              height: item.shipment_height,
              weight: item.shipment_weight,
            };
            console.log(order);

            console.log("order: ", order);

            promises.push(
              axios
                .post(
                  process.env.REACT_APP_API_URL +
                  "api/v1/order/createShiprocketOrder",
                  order
                )
                .then((response) => {
                  console.log("response", response);
                })
            );

            console.log("marking paid", item.cart_id);
            promises.push(
              axios
                .put(
                  process.env.REACT_APP_API_URL +
                  `api/v1/cart/paid/${item.cart_id}`
                )
                .then((response) => {
                  console.log("response", response);
                })
            );

            internal_order = {
              order_id: ref_order_id, // for main reference
              order_date: ref_order_dt,
              shipment_order_id: shipment_order_id,
              username: username,
              vendor_id: item.vendor_id,
              vendor_username: item.vendor_username,
              payment_id: data.razorpayPaymentId,
              product_id: item.product_id,
              variant_id: item.variant_id,
              product_name: item.product_name,
              variant_name: item.variant_name,
              quantity: item.quantity,
              image_urls: item.image_urls,
              offer_price: item.offer_price,
              maximum_retail_price: item.maximum_retail_price,
              expected_delivery_time: "P3D",
              cashback: item.cashback,
              discount: item.discount,
              cgst: item.cgst ?? 9,
              sgst: item.sgst ?? 9,
              igst: item.igst ?? 0,
              utgst: item.utgst ?? 0,
              payment_status: "PAID",
              shipment_status: "INITIATED",
              shipment_cost: item.shipment_cost,
              order_status: "PENDING",
              return_policy: item.return_policy,
              cancel_policy: item.cancel_policy,
              days_to_return: item.days_to_return,
              variant_level1: item.variant_level1 ?? "",
              variant_level2: item.variant_level2 ?? "",
              variant_level3: item.variant_level3 ?? "",
              shipping_name: shippingAddress.first_name + " " + shippingAddress.last_name,
              shipping_address: shippingAddress.line1_address + ", " + shippingAddress.line2_address,
              shipping_city: shippingAddress.city,
              shipping_state: shippingAddress.state,
              shipping_zipcode: shippingAddress.zipcode,
              shipping_phone: shippingAddress.phone,
              shipping_email: shippingAddress.email,
            };

            console.log("internal order", internal_order);
            promises.push(
              axios
                .post(
                  process.env.REACT_APP_API_URL + "api/v1/order/createOrder",
                  internal_order
                )
                .then((response) => {
                  console.log("response", response);
                })
            );

            // loyalty rewards
            loyalty_rewards = {
              username: username,
              vendor_username: item.vendor_username,
              loyalty_txn_id: uuidv4(),
              loyalty_points: item.offer_price,
              loyalty_txn_date: ref_order_dt,
              loyalty_type: "STORE",
              loyalty_category: "VENDOR",
              product_id: item.product_id,
              variant_id: item.variant_id,
              order_id: ref_order_id,
            };

            console.log("loyalty rewards", loyalty_rewards);

            if (keycloak.authenticated) {
              promises.push(
                axios
                  .post(
                    process.env.REACT_APP_API_URL + "api/v1/rewards/loyalty",
                    loyalty_rewards
                  )
                  .then((response) => {
                    console.log("response", response);
                  })
              );

              if (item.cashback > 0) {
                cashback = {
                  username: username,
                  vendor_username: item.vendor_username,
                  cashback: item.cashback,
                  cashback_date: ref_order_dt,
                  order_id: ref_order_id,
                };
              }
              console.log("cashback", cashback);

              promises.push(
                axios
                  .post(
                    process.env.REACT_APP_API_URL + "api/v1/rewards/cashback",
                    cashback
                  )
                  .then((response) => {
                    console.log("response", response);
                  })
              );
            }
          });

          Promise.all(promises).then(() => {
            console.log("executed correctly..");
            navigate("/thankyou");
          });
        }
      },
      prefill: {
        name: NAME,
        email: EMAIL,
      },
      notes: {
        address:
          "36 No. 302 Emami Nest 8th Main 16th Cross Malleswaram, Bangalore - 560003",
      },
      theme: {
        color: { light_background },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    // update
  };

  const handleCashOnDelivery = async () => {
    // Upload order to order
  };

  const updateQuantity = async (cart_id, quantity) => {
    const url =
      process.env.REACT_APP_API_URL + `api/v1/cart/updateQuantity/${cart_id}`;

    const payload = {
      quantity: quantity,
    };

    try {
      message.destroy(); // Clear any existing messages
      await axios.put(url, payload).then((response) => {
        console.log("Cart updated", response);
        fetchCart(); // update cart content and amounts
        message.success("Cart updated", response.data.message);
        setTimeout(() => {
          message.destroy(); // Clear the message after 2 seconds
        }, 2000);
      });
    } catch (error) {
      console.log("Error updating cart", error.response);
      message.destroy(); // Clear any existing messages
      if (error.response) {
        message.error("Error updating cart: " + error.response.data.message);
      } else {
        message.error("Error updating cart");
      }
      setTimeout(() => {
        message.destroy(); // Clear the message after 2 seconds
      }, 2000);
    }
  };

  const viewDetails = (row) => {
    // navigate(`/app/apps/ecommerce/edit-product/${row.id}`);
    // disabled for now
  };

  const deleteRow = async (row) => {
    const objKey = "cart_id";

    let data = lineItems;
    if (selectedRows.length > 1) {
      for (let elm of selectedRows) {
        data = utils.deleteArrayRow(data, objKey, elm.cart_id);
        const url =
          process.env.REACT_APP_API_URL + `api/v1/cart/abandon/${elm.cart_id}`;

        try {
          await axios.put(url).then((value) => {
            console.log("Cart abandoned");
          });
        } catch {
          console.log("some error abandoning the cart");
        }


        setLineItems(data);
        setSelectedRows([]);
      }
    } else {
      const url =
        process.env.REACT_APP_API_URL + `api/v1/cart/abandon/${row.cart_id}`;

      try {
        await axios.put(url).then((value) => {
          console.log("Cart abandoned", value);
        });
      } catch {
        console.log("some error abandoning the cart");
      }
      data = utils.deleteArrayRow(data, objKey, row.cart_id);
      setLineItems(data);
    }
    await fetchCartCount();

  };

  const renderProductItem = (item) => (
    <Card style={{ marginBottom: 16 }}>
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <AvatarStatus size={128} type="square" src={item.image_urls[0]} />
          <h4>{item.product_name}</h4>
          {item.variant_level1 && (
            <p>
              {item.variant_level1} {item.variant_level2} {item.variant_level3}
            </p>
          )}
        </Col>
        <Col xs={24} sm={8}>
          <p>
            <strong>Quantity:</strong>
            <InputNumber
              min={0}
              max={100}
              value={item.quantity}
              onStep={(quantity) => updateQuantity(item.cart_id, quantity)}
            />
          </p>
        </Col>
        <Col xs={24} sm={8}>
          <Row gutter={16} justify="space-between">
            <Col span={12} style={{ textAlign: "left" }}>Price<p style={{ fontSize: "12px" }}>Inclusive of all taxes</p></Col>
            <Col span={12} style={{ textAlign: "right" }}>{rupee} {formatCurrency(item.price)}</Col>
          </Row>
          <Row gutter={16} justify="space-between">
            <Col span={12} style={{ textAlign: "left" }}>GST</Col>
            <Col span={12} style={{ textAlign: "right" }}>{rupee} {formatCurrency(item.gst)}</Col>
          </Row>
          <Divider />
          <Row gutter={16} justify="space-between">
            <Col span={12} style={{ textAlign: "left" }}>Payable</Col>
            <Col span={12} style={{ textAlign: "right" }}>{rupee} {formatCurrency(item.price)}</Col>
          </Row>
          <div style={{ textAlign: "right" }}>
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => deleteRow(item)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => (
        <div>
          <NumberFormat
            displayType={"text"}
            value={(Math.round(amount * 100) / 100).toFixed(2)}
            prefix={"\u20B9 "}
            thousandSeparator={true}
          />
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
    },
  };

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    const searchArray = e.currentTarget.value ? lineItems : [];
    const data = utils.wildCardSearch(searchArray, value);
    setLineItems(data);
    setSelectedRowKeys([]);
  };

  const handleShowCategory = (value) => {
    if (value !== "All") {
      const key = "category";
      const data = utils.filterArray(lineItems, key, value);
      setLineItems(data);
    } else {
      setLineItems(lineItems);
    }
  };

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const createInvoice = async (cart) => {
    const shippingAddress = JSON.parse(
      localStorage.getItem("shippingAddress")
    )[0];
    console.log("shipping address is ", shippingAddress);

    var items = [];
    for (var i = 0; i < cart.length; i++) {
      console.log(cart[i]);
      items.push({
        name: cart[i].product_name,
        description: cart[i].variant_name,
        amount: Math.ceil(cart[i].offer_price * 100), // inclusive of shipping and discounts for now
        currency: "INR",
        quantity: 1,
      });
    }

    const json_data = {
      type: "invoice",
      description: "neonyte.com Invoice",
      customer: {
        name: shippingAddress.first_name + " " + shippingAddress.last_name,
        contact: shippingAddress.phone,
        email: shippingAddress.email,
        shipping_address: {
          line1: shippingAddress.line1_address,
          line2: shippingAddress.line2_address,
          city: shippingAddress.city,
          state: shippingAddress.state,
          zipcode: shippingAddress.zipcode,
          country: "IN",
        },
        billing_address: {
          line1: shippingAddress.line1_address,
          line2: shippingAddress.line2_address,
          city: shippingAddress.city,
          state: shippingAddress.state,
          zipcode: shippingAddress.zipcode,
          country: "IN",
        },
      },
      line_items: items,
      sms_notify: 1,
      email_notify: 1,
      currency: "INR",
      expire_by: Math.floor((Date.now() + 60 * 60 * 1000) / 1000),
      terms:
        "Invoice expires within in one hour from the time of receipt of the invoice\n \
In case of failed payments, Invoice needs to be raised again by carrying out CheckOut\n \
Please refer to our Terms & Conditions https://www.neonyte.com/terms_conditions \n \
and Return Policy for details https://www.neonyte.com/return_policy",
      comment: "This invoice does not include shipping costs and discounts",
    };

    console.log(json_data);
    setInvoice(json_data);
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "api/v1/invoice/add", json_data)
        .then((response) => {
          console.log(response.status);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectShippingAddress = (id, shippingAddressDict) => {
    console.log("Selected ID", id);
    console.log("Selected shippingAddressList", shippingAddressDict);
    const address = shippingAddressDict.filter((item) => {
      return item.pickup_location === id;
    });
    localStorage.setItem("shippingAddress", JSON.stringify(address));
    console.log("address is", address);
    const shippingAddress = JSON.parse(localStorage.getItem("shippingAddress"));
    console.log("shipping address is ", shippingAddress);
    setShippingZipcode(address[0].zipcode);
    if (shippingAddress !== undefined || shippingAddress !== "") {
      setDisableCheckout(false);
    }
  };

  const addShippingAddress = async (values) => {
    const url = process.env.REACT_APP_API_URL + "api/v1/address/add";
    console.log("values", values);
    console.log("Address", values);
    values["username"] = username;
    values["address_category"] = "USER"; // DO NOT CHANGE THIS...
    values["address_type"] = "SHIPPING";
    values["pickup_location"] = uuidv4();

    try {
      axios.post(url, values);
      setIsModalOpen(false);
    } catch {
      console.log("Error adding shipping address");
    }
  };

  const fetchPastOrders = async () => {
    const url = process.env.REACT_APP_API_URL + `api/v1/orders/fetch/${username}`;
    try {
      const response = await axios.get(url);
      const pastOrders = response.data.orders;
      console.log("Past Orders:", pastOrders);
      // You can set the past orders to a state variable if needed
      // setPastOrders(pastOrders);

      let completed_orders = 0;
      if (pastOrders.length > 0 && pastOrders.shipment_status === "DELIVERED") {
        completed_orders += 1;
      }
      if (completed_orders > 1)
        setDisableCashOnDelivery(false);

    } catch (error) {
      console.log("Error fetching past orders:", error);
    }
  };

  useEffect(() => {
    console.log("Recomputing shipping cost");
    fetchCart();
    fetchPastOrders(); // Fetch past orders on component mount
  }, [shippingZipcode]);

  return (
    <>
      <Card>
        <Flex className="mb-1" mobileFlex={false}>
          <h2>Shopping Cart</h2>
        </Flex>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16}>
            <div className="list-responsive">
              <List
                itemLayout="vertical"
                dataSource={lineItems}
                renderItem={renderProductItem}
              />
            </div>
            <div style={{ alignItems: "center" }}>
              <Card
                style={{ backgroundColor: light_background }}
                title="Select or Add Delivery Address"
              >
                <Row gutter={16} item xs={24} sm={24} md={24} lg={24}>
                  <Col xs={24} sm={24} md={24} lg={6}>
                    <Button
                      style={{
                        width: "auto",
                        backgroundColor: "#E75627",
                        color: "white",
                        verticalAlign: "middle",
                      }}
                      onClick={handleShowModal}
                    >
                      Delivery Address
                    </Button>
                    <Modal
                      title="Delivery Address"
                      centered
                      footer={null}
                      open={isModalOpen}
                      onCancel={handleCancel}
                    >
                      <Divider />
                      <div style={{ color: "red", fontSize: "18px" }}>
                        <p> </p>
                        <Card
                          style={{
                            backgroundColor: light_background,
                          }}
                        >
                          <Form
                            name="shipping_form"
                            layout="vertical"
                            onFinish={addShippingAddress}
                          >
                            <Row gutter={16}>
                              {parsed_token && (
                                <>
                                  <Col xs={24} sm={24} md={16}>
                                    <Form.Item
                                      label="Pick Location Nickname"
                                      name="address_nickname"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8}>
                                    <Form.Item
                                      label="default"
                                      name="default"
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                      className="w-100"
                                    >
                                      <Select>
                                        <Option key={"true"}>Yes</Option>
                                        <Option key={"false"}>No</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  label="first_name"
                                  name="first_name"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  label="Last Name"
                                  name="last_name"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  label="Phone"
                                  name="phone"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Enter 10 digit mobile phone number",
                                    },
                                    { len: 10, message: "Length must be 10" },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  label="e-mail"
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                  label="Address1"
                                  name="line1_address"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <Form.Item label="Address2" name="line2_address">
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  label="Postal code"
                                  name="zipcode"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  label="City"
                                  name="city"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  label="State"
                                  name="state"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Button
                                style={{ backgroundColor: "#E75627" }}
                                type="primary"
                                htmlType="submit"
                              >
                                Save Changes
                              </Button>{" "}
                            </Row>
                          </Form>
                        </Card>
                      </div>
                    </Modal>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={18}>
                    <AddressList selectShippingAddress={selectShippingAddress} />
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Card
              style={{ backgroundColor: light_background }}
              title="Billing Details"
            >
              <Card>
                <Row gutter={16}>
                  <Col span={12} alignItems="left">Items Cost<p style={{ fontSize: "12px" }}>Inclusive of all taxes</p></Col>
                  <Col span={12} alignItems="right">{rupee} {formatCurrency(postGSTAmount)}</Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>Shipping & Handling</Col>
                  <Col span={12}>{rupee} {formatCurrency(totalShippingCost)}</Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                  <Col span={12}>Order Total</Col>
                  <Col span={12}>{rupee} {formatCurrency(totalPayableAmount)}</Col>
                </Row>
              </Card>
              <div id="inner2" style={{ padding: "10px" }}>
                <Button
                  style={{
                    width: "200px",
                    backgroundColor: "#E75627",
                    color: "white",
                    verticalAlign: "middle",
                  }}
                  onClick={handlePayment}
                  disabled={disableCheckout}
                >
                  Checkout
                </Button>
                {parsed_token && (
                  <Button
                    style={{
                      width: "200px",
                      backgroundColor: "#E75627",
                      color: "white",
                      verticalAlign: "middle",
                    }}
                    onClick={handleCashOnDelivery}
                    disabled={disableCashOnDelivery}
                  >
                    Cash on Delivery
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Cart;
