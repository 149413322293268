import { useState } from "react";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
const { Search } = Input;

const domain = process.env.REACT_APP_DOMAIN;

const SearchBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (value) => {
    setIsLoading(true);
    navigate(`/search?q=${value}`);
  };

  return (
    <div className="header-search">
      <Search
        placeholder="search products"
        loading={isLoading}
        onPressEnter={(event) => handleChange(event.target.value)}
        onSearch={handleChange}
        style={{ color: "#f48e6f" }}
      />
    </div>
  );
};

export default SearchBar;
