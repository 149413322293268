import Cart from "./Cart";
import "assets/css/app_view.css";

const ShoppingCart = () => {
  return (
    <div className="main-container">
      <Cart />
    </div>
  );
};

export default ShoppingCart;
