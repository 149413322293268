import React, { useState, useCallback } from "react";
import { Map, APIProvider, AdvancedMarker } from "@vis.gl/react-google-maps";
import { Input, Button, Row, Col } from "antd";
import { setDefaults, fromAddress, fromLatLng } from "react-geocode";

const containerStyle = {
  width: "100%",
  height: "400px",
  minHeight: "400px",
};

const center = {
  lat: 12.979312,
  lng: 77.59124,
};

setDefaults({
  key: process.env.REACT_APP_GOOGLE_GEOCODING_API,
  language: "en",
  region: "in",
});

const GeoReverseCoding = ({
  onLocationSelect,
  deliverToZipcode,
  setDeliverToZipcode,
}) => {
  const [latitude, setLatitude] = useState(center.lat);
  const [longitude, setLongitude] = useState(center.lng);
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);

  const handleReverseGeocode = useCallback(() => {
    fromLatLng(latitude.toFixed(6), longitude.toFixed(6)).then(
      (response) => {
        const result = response.results[0];
        if (result) {
          setAddress(result.formatted_address);
        } else {
          setAddress("No results found");
        }
      },
      (error) => {
        setAddress("Geocoder failed due to: " + error);
      }
    );
  }, [latitude, longitude]);

  const handleMapClick = useCallback(
    (event) => {
      try {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLatitude(parseFloat(lat.toFixed(6)));
        setLongitude(parseFloat(lng.toFixed(6)));
        setMarkerPosition({ lat, lng });
        handleReverseGeocode();
        onLocationSelect(lat, lng);
      } catch (error) {
        console.error("Error handling map click:", error);
      }
    },
    [handleReverseGeocode, onLocationSelect]
  );

  const handleMarkerDragEnd = useCallback(
    (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setLatitude(parseFloat(lat.toFixed(6)));
      setLongitude(parseFloat(lng.toFixed(6)));
      setMarkerPosition({ lat, lng });
      handleReverseGeocode();
      onLocationSelect(lat, lng);
    },
    [handleReverseGeocode, onLocationSelect]
  );

  const handleZipcodeSearch = () => {
    fromAddress(zipcode).then(
      (response) => {
        try {
          const location = response.results[0].geometry.location;
          const lat = location.lat;
          const lng = location.lng;
          setLatitude(parseFloat(lat.toFixed(6)));
          setLongitude(parseFloat(lng.toFixed(6)));
          setMarkerPosition({ lat, lng });
          setAddress(response.results[0].formatted_address);
          setDeliverToZipcode(zipcode);
          onLocationSelect(lat, lng);
        } catch (error) {
          setAddress("No results found");
        }
      },
      (error) => {
        setAddress("Geocoder failed due to: " + error);
      }
    );
  };

  const handleAddressSearch = () => {
    fromAddress(searchAddress).then(
      (response) => {
        try {
          const location = response.results[0].geometry.location;
          const lat = location.lat;
          const lng = location.lng;
          setLatitude(lat);
          setLongitude(lng);
          setMarkerPosition({ lat, lng });
          setAddress(response.results[0].formatted_address);
          setDeliverToZipcode(
            response.results[0].address_components.find((component) =>
              component.types.includes("postal_code")
            ).long_name
          );
          onLocationSelect(lat, lng);
        } catch (error) {
          setAddress("No results found");
        }
      },
      (error) => {
        setAddress("Geocoder failed due to: " + error);
      }
    );
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_GEOCODING_API}>
      <div>
        <Row gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Latitude"
              value={latitude}
              onChange={(e) =>
                setLatitude(parseFloat(e.target.value.toFixed(6)))
              }
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Longitude"
              value={longitude}
              onChange={(e) =>
                setLongitude(parseFloat(e.target.value.toFixed(6)))
              }
            />
          </Col>
          <Col span={8}>
            <Button type="primary" onClick={handleReverseGeocode}>
              Get Address
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={16}>
            <Input
              placeholder="Zipcode"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <Button type="primary" onClick={handleZipcodeSearch}>
              Search by Zipcode
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={16}>
            <Input
              placeholder="Address"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <Button type="primary" onClick={handleAddressSearch}>
              Search by Address
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Input.TextArea
              rows={4}
              value={address}
              readOnly
              placeholder="Address will be displayed here"
            />
          </Col>
        </Row>
        <div style={{ width: "100%", height: "400px" }}>
          <Map
            mapContainerStyle={containerStyle}
            defaultCenter={{ lat: latitude, lng: longitude }}
            defaultZoom={10}
            onClick={handleMapClick}
            mapId="1a31906b11da42f8" // setup in the google maps platform
            options={{
              gestureHandling: "greedy",
            }}
          >
            <AdvancedMarker
              position={markerPosition}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          </Map>
        </div>
      </div>
    </APIProvider>
  );
};

export default GeoReverseCoding;
