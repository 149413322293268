import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { Row, Col, Radio, Space, message } from "antd"; // Import message from antd
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ProductCard.css"; // Import custom CSS file
import ProductAttributes from "./ProductAttributes";
import { Link } from "react-router-dom"; // Import Link
import { PARSED_TOKEN } from "constants/AuthConstant";
import { useDispatch } from "react-redux";
import { setCartCount } from "store/slices/cartSlice";
const domain = process.env.REACT_APP_DOMAIN;
const rupee = String.fromCharCode("\u20B9");

const formatCurrency = (value) => {
  return value
    ? Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value)
    : 0;
};
const ProductCard = ({ product_id }) => {
  const [product, setProduct] = useState(null);
  const [isLevel1, setIsLevel1] = useState();
  const [isLevel2, setIsLevel2] = useState();
  const [selectedLevel1, setSelectedLevel1] = useState();
  const [selectedLevel2, setSelectedLevel2] = useState();
  const [level1List, setLevel1List] = useState([]);
  const [level2List, setLevel2List] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [colorImages, setColorImages] = useState({});
  const [images, setImages] = useState([]);
  let isAddToCartDisabled = true;

  const parsed_token = JSON.parse(localStorage.getItem(PARSED_TOKEN));
  const username = parsed_token?.preferred_username
    ? parsed_token.preferred_username
    : localStorage.getItem("dummy_username");

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/products/fetchProduct/${product_id}`
        );
        let product_data = response.data[0];
        setProduct(product_data);
        // Set color-specific 
        const json_color_images = JSON.parse(product_data.color_image_urls) || {};
        console.log("json_color_images:", json_color_images);
        setColorImages(json_color_images || {});
        setImages(
          product_data.image_urls.map((image) => ({
            original: image,
            thumbnail: image,
          }))
        );
        let level1 = [],
          level2 = [];

        if (product_data.variants_level1_list.length > 0) {
          setIsLevel1(true);
          product_data.variants_level1_list.forEach((item) => {
            level1.push({
              name: item,
              disabled: true,
            });
          });
          setLevel1List(level1);
        }

        if (product_data.variants_level2_list.length > 0) {
          setIsLevel2(true);
          product_data.variants_level2_list.forEach((item) => {
            level2.push({
              name: item,
              disabled: true,
            });
          });
          setLevel2List(level2);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/rating/fetchByProduct?product_id=${product_id}`
        );
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchProduct();
    fetchReviews();
  }, [product_id]);

  const updateLevel1ActiveList = async () => {
    let level1 = [];

    let variant_list = [];
    product.product_variants.forEach((item) => {
      variant_list.push(item);
    });

    let level1_list = [];
    variant_list.forEach((item) => {
      level1_list.push(item.variant_group_level1_name);
    });
    const level1_set = new Set(level1_list);
    const unqiue_level1 = [...level1_set];
    if (unqiue_level1.length > 0) {
      level1List.forEach((item) => {
        if (unqiue_level1.includes(item.name))
          level1.push({
            name: item.name,
            disabled: false,
          });
        else {
          level1.push({
            name: item.name,
            disabled: true,
          });
        }
      });
    } else {
      level1List.forEach((item) => {
        level1.push({
          name: item.name,
          disabled: true,
        });
      });
    }
    setLevel1List(level1);
  };

  const updateLevel2ActiveList = async (level1) => {
    let level2 = [];

    let variant_list = [];
    product.product_variants.forEach((item) => {
      if (item.variant_group_level1_name === level1) variant_list.push(item);
    });

    let level2_list = [];
    variant_list.forEach((item) => {
      level2_list.push(item.variant_group_level2_name);
    });
    const level2_set = new Set(level2_list);
    const unqiue_level2 = [...level2_set];
    if (unqiue_level2.length > 0) {
      level2List.forEach((item) => {
        if (unqiue_level2.includes(item.name))
          level2.push({
            name: item.name,
            disabled: false,
          });
        else {
          level2.push({
            name: item.name,
            disabled: true,
          });
        }
      });
    } else {
      level2List.forEach((item) => {
        level2.push({
          name: item.name,
          disabled: true,
        });
      });
    }

    setLevel2List(level2);
  };

  const onChangeLevel1 = async (e) => {
    updateLevel2ActiveList(e.target.value);
    setSelectedLevel1(e.target.value);
    setSelectedLevel2(undefined);
    // Update color-specific images based on selected color
    const selectedColorImages = colorImages[e.target.value];
    setImages(
      selectedColorImages
        ? selectedColorImages.map((image) => ({
          original: image.url,
          thumbnail: image.url,
        }))
        : product.image_urls.map((image) => ({
          original: image,
          thumbnail: image,
        }))
    );
  };
  const onChangeLevel2 = async (e) => {
    updateLevel1ActiveList(e.target.value);
    setSelectedLevel2(e.target.value);
  };

  useEffect(() => {
    if (product) {
      if (isLevel1) {
        updateLevel1ActiveList();
      }
      if (isLevel2) {
        updateLevel2ActiveList(level1List[0]);
      }
    }
  }, [product]);

  const addToCart = async () => {
    // Handle add to cart logic here
    console.log("Product added to cart:", product);
    console.log("Selected Color:", selectedLevel1);
    console.log("Selected Size:", selectedLevel2);

    const payload = {
      username: username,
      vendor_id: product.vendor_id,
      vendor_username: product.vendor_username,
      product_id: product.product_id,
      product_name: product.product_name,
      variant_level1: selectedLevel1 ? selectedLevel1 : "",
      variant_level2: selectedLevel2 ? selectedLevel2 : "",
      variant_level3: "",
      cart_status: "IN_CART",
      quantity: 1,
      offer_price: product.offer_price,
      maximum_retail_price: product.maximum_retail_price,
      cgst: product.cgst,
      sgst: product.sgst,
      cashback: product.cashback,
      discount: product.discount,
      shipment_length: product.shipment_length,
      shipment_width: product.shipment_width,
      shipment_height: product.shipment_height,
      shipment_weight: product.shipment_weight,
      shipment_fees: product.shipment_fees,
      vendor_shipment: product.vendor_shipment,
      pickup_location: product.pickup_location,
      hsn_code: product.hsn_code,
      image_urls: product.image_urls,
      category: product.category,
      sub_category1: product.sub_category1,
      primary_category: product.primary_category,
      secondary_category: product.secondary_category,
      tertiary_category: product.tertiary_category,
      return_policy: product.return_policy,
      cancel_policy: product.cancel_policy,
      days_to_return: product.days_to_return,
    };

    const url = `${process.env.REACT_APP_API_URL}api/v1/cart/add`;
    console.log("payload:", payload);
    try {
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        message.success(`Product added: ${payload.product_name}`);
      } else if (response.status !== 200) {
        message.error(`Product not added: ${payload.product_name}, ${response.data.message}`);
      }

      const cartResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/cart/user/${username}`
      );
      dispatch(setCartCount(cartResponse.data.rows.length)); // Update cart count in Redux state
    } catch {
      console.log("Cannot add new product");
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  if (isLevel1 && isLevel2) {
    isAddToCartDisabled = !selectedLevel1 || !selectedLevel2;
  } else if (isLevel1) {
    isAddToCartDisabled = !selectedLevel1;
  } else {
    isAddToCartDisabled = false;
  }

  return (
    <Card style={{ width: "80%", margin: "auto", padding: "20px" }}>
      <CardContent>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <ImageGallery
                items={images}
                thumbnailPosition="left"
                showNav={false} // Disable arrow buttons
              />
            </Col>
            <Col xs={24} md={12}>
              <Space direction="vertical">
                <Typography variant="h4">{product.product_name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Sold by:{" "}
                  <Link to={`/vendor/${product.vendor_id}`}>
                    {product.vendor_username}
                  </Link>
                </Typography>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{ color: "#E75627" }}
                >
                  {rupee} {formatCurrency(product.offer_price)}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  {product.description}
                </Typography>
                {isLevel1 && (
                  <div style={{ marginTop: 16 }}>
                    <Typography variant="subtitle1">
                      {product.variants_level1_name}
                    </Typography>
                    <Radio.Group
                      value={selectedLevel1}
                      onChange={onChangeLevel1}
                    >
                      {level1List.map((level1, index) => (
                        <Radio.Button
                          key={index}
                          value={level1.name}
                          disabled={level1.disabled}
                        >
                          {level1.name}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                )}
                {isLevel2 && (
                  <div style={{ marginTop: 16 }}>
                    <Typography variant="subtitle1">
                      {product.variants_level2_name}
                    </Typography>
                    <Radio.Group
                      value={selectedLevel2}
                      onChange={onChangeLevel2}
                    >
                      {level2List.map((level2, index) => (
                        <Radio.Button
                          key={level2.name}
                          value={level2.name}
                          disabled={level2.disabled}
                        >
                          {level2.name}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2 }}
                  onClick={addToCart}
                  disabled={isAddToCartDisabled}
                  style={{
                    backgroundColor: isAddToCartDisabled ? "#ccc" : "#1976d2",
                  }}
                >
                  Add to Cart
                </Button>
              </Space>
            </Col>
          </Row>
          {product.product_features && (
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <Card>
                  <CardHeader title="Product Attributes" />
                  <ProductAttributes attributes={product.product_features} />
                </Card>
              </Col>
            </Row>
          )}
          {reviews && (
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <Typography variant="h5" sx={{ marginTop: 4 }}>
                  Customer Reviews
                </Typography>
                <List>
                  {reviews.map((review, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={review.username}
                        secondary={review.product_comment}
                        style={{ color: "#E75627" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Col>
            </Row>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  product_id: PropTypes.string.isRequired,
};

export default ProductCard;
