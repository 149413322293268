// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favorite-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    /* Move to the top left */
    font-size: 24px;
    color: red;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    /* Add background for better visibility */
    border-radius: 50%;
    /* Make it circular */
    padding: 5px;
    /* Add padding */
    z-index: 1;
    /* Ensure it is above other elements */
}

.card-cover {
    width: 100%;
    height: 192px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* Ensure the heart icon is positioned correctly */
}

.card-cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/layout-components/ProductDisplay/ProductCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,yBAAyB;IACzB,eAAe;IACf,UAAU;IACV,eAAe;IACf,oCAAoC;IACpC,yCAAyC;IACzC,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kDAAkD;AACtD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".favorite-icon {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    /* Move to the top left */\n    font-size: 24px;\n    color: red;\n    cursor: pointer;\n    background: rgba(255, 255, 255, 0.5);\n    /* Add background for better visibility */\n    border-radius: 50%;\n    /* Make it circular */\n    padding: 5px;\n    /* Add padding */\n    z-index: 1;\n    /* Ensure it is above other elements */\n}\n\n.card-cover {\n    width: 100%;\n    height: 192px;\n    overflow: hidden;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    /* Ensure the heart icon is positioned correctly */\n}\n\n.card-cover-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
