import React from "react";
import { useParams } from "react-router-dom";
import ProductCard from "components/layout-components/ProductCard";

import "assets/css/app_view.css";

const ProductPage = () => {
  const { product_id } = useParams();
  console.log("product_id", product_id);
  return (
    <>
      <div className="main-container">
        <ProductCard product_id={product_id} />
      </div>
    </>
  );
};

export default ProductPage;
