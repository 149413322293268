import React, { useState } from "react";
import { Badge } from "antd";
import ProductCard from "./ProductCard";

const Product = (props) => {
  const [brand, setBrand] = useState();
  const product = props.input;

  const cashback = props.cashback;
  const discount = props.discount;

  const updateBrand = (brand_name) => {
    console.log("brand selected = ", brand_name);
    setBrand(brand_name);
  };

  const formatCurrency = (value) => {
    return value
      ? Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(value)
      : 0;
  };

  return cashback ? (
    <Badge.Ribbon
      text={
        <a style={{ color: "white" }}>
          {"Cashback: \u20B9 "}
          {formatCurrency(product.cashback)}
        </a>
      }
      color="#E75627"
    >
      <ProductCard input={props.input} />
    </Badge.Ribbon>
  ) : discount && product.discount > 10 ? (
    <Badge.Ribbon
      text={
        <a style={{ color: "white" }}>
          {"Discount: "}
          {Math.round(product.discount, 0)}
          {"%"}
        </a>
      }
      color="#E75627"
    >
      <ProductCard input={product} />
    </Badge.Ribbon>
  ) : (
    <ProductCard input={product} />
  );
};

export default Product;
