import { Button, Card, Carousel, Image, List, Row, Col, message, Modal } from "antd"; // Import Modal from antd
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PARSED_TOKEN } from "constants/AuthConstant";
import axios from "axios";
import { HeartOutlined, HeartFilled, EyeOutlined } from "@ant-design/icons"; // Import Eye Icon
import "./ProductCard.css"; // Import CSS file for styling
const rupee = String.fromCharCode("\u20B9");
import { useDispatch } from "react-redux";
import { setCartCount } from "store/slices/cartSlice";

const formatCurrency = (value) => {
  return value
    ? Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value)
    : 0;
};

const ProductCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false); // State to track if the product is a favorite
  const [carouselAutoplay, setCarouselAutoplay] = useState(false); // State to control carousel autoplay
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const parsed_token = JSON.parse(localStorage.getItem(PARSED_TOKEN));
  let username = parsed_token
    ? parsed_token.preferred_username
    : localStorage.getItem("dummy_username");

  const product = props.input;

  const fetchImages = () => {
    const files = [];
    let uid = 1;
    if (product.image_urls) {
      product.image_urls.forEach((element) => {
        let mb = {
          uid: uid,
          status: "done",
          url: element,
        };
        uid = uid + 1;
        files.push(mb);
      });
    }
    setFileList(files);
  };

  const addToCart = async (payload) => {

    let url = process.env.REACT_APP_API_URL + "api/v1/cart/add";
    let dummy_username = localStorage.getItem("dummy_username");
    if (!parsed_token && !dummy_username) {
      dummy_username = "Guest" + uuidv4().slice(-12).toUpperCase();
      localStorage.setItem("dummy_username", dummy_username);
    }

    dummy_username = localStorage.getItem("dummy_username");
    username = parsed_token?.preferred_username || dummy_username;
    payload.username = username;

    try {
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        message.success(`Product added to Cart: ${payload.product_name}`);
      }
      if (response.status !== 200) {
        message.error(`Product not added to Cart: ${response.data.message}`);
      }
    } catch {
      console.log("Cannot add new product");
    }

    // update cart count
    url = process.env.REACT_APP_API_URL + `api/v1/cart/user/${username}`;
    await axios
      .get(url)
      .then((response) => {
        console.log("rows:", response.data.rows);
        let product_list = response.data.rows;
        let product_count = product_list.length;
        console.log("product_count:", product_count);
        dispatch(setCartCount(product_count)); // Update cart count in Redux state
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const toggleFavorite = (product) => {
    let favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    if (isFavorite) {
      favorites = favorites.filter(
        (fav) => fav.product_id !== product.product_id
      );
      setIsFavorite(false);
      message.info(`Product removed from favorites: ${product.product_name}`);
    } else {
      if (!favorites.some((fav) => fav.product_id === product.product_id)) {
        favorites.push(product);
        setIsFavorite(true);
        message.success(`Product added to favorites: ${product.product_name}`);
      }
    }
    localStorage.setItem("favorites", JSON.stringify(favorites));
    if (props.onFavoriteToggle) {
      props.onFavoriteToggle(product.product_id, !isFavorite);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { Meta } = Card;

  useEffect(() => {
    fetchImages();
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setIsFavorite(
      favorites.some((fav) => fav.product_id === product.product_id)
    );
  }, [product.product_id]);

  return (
    <>
      <Card
        className="Container"
        hoverable
        style={{
          width: 256,
          height: 360, // Fix the card height to 360px
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          position: "relative", // Add position relative for overlay
        }}
        cover={
          <div
            className="card-cover"
            onMouseEnter={() => setCarouselAutoplay(true)}
            onMouseLeave={() => setCarouselAutoplay(false)}
          >
            {product.image_urls && product.image_urls.length > 0 && (
              <Carousel autoplay={carouselAutoplay}>
                {product.image_urls.map((url, index) => (
                  <div key={index}>
                    <img
                      src={url}
                      alt={`product-${index}`}
                      className="card-cover-image"
                      style={{
                        width: "100%",
                        height: "256px",
                        objectFit: "cover",
                        margin: "auto",
                      }} // Adjust width, height, and center the image
                    />
                  </div>
                ))}
              </Carousel>
            )}
            {isFavorite ? (
              <HeartFilled
                onClick={() => toggleFavorite(product)}
                className="favorite-icon"
              />
            ) : (
              <HeartOutlined
                onClick={() => toggleFavorite(product)}
                className="favorite-icon"
              />
            )}
            <EyeOutlined
              onClick={showModal}
              className="preview-icon"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "24px",
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
              }}
            />
          </div>
        }
        actions={
          product.variants_level1_list
            ? [
              <Button
                style={{ textAlign: "center", width: "80%" }}
                onClick={() => navigate(`/product/${product.product_id}`)}
              >
                View
              </Button>,
            ]
            : [
              <Button
                style={{ textAlign: "center", width: "80%" }}
                onClick={() => navigate(`/product/${product.product_id}`)}
              >
                View Details
              </Button>,
              <Button
                type="primary"
                style={{
                  backgroundColor: "#ec6a43",
                }}
                onClick={() =>
                  addToCart({
                    username: username,
                    vendor_username: product.vendor_username,
                    vendor_id: product.vendor_id,
                    product_id: product.product_id,
                    product_name: product.product_name,
                    variant_level1: "",
                    variant_level2: "",
                    variant_level3: "",
                    cart_status: "IN_CART",
                    quantity: 1,
                    offer_price: product.offer_price,
                    maximum_retail_price: product.maximum_retail_price,
                    cgst: product.cgst,
                    sgst: product.sgst,
                    cashback: product.cashback,
                    discount: product.discount,
                    shipment_length: product.shipment_length,
                    shipment_width: product.shipment_width,
                    shipment_height: product.shipment_height,
                    shipment_weight: product.shipment_weight,
                    shipment_fees: product.shipment_fees,
                    vendor_shipment: product.vendor_shipment,
                    pickup_location: product.pickup_location,
                    hsn_code: product.hsn_code,
                    image_urls: product.image_urls,
                    category: product.category,
                    sub_category1: product.sub_category1,
                    primary_category: product.primary_category,
                    secondary_category: product.secondary_category,
                    tertiary_category: product.tertiary_category,
                    return_policy: product.return_policy,
                    cancel_policy: product.cancel_policy,
                    days_to_return: product.days_to_return,
                  })
                }
              >
                Add to Cart
              </Button>,
            ]
        }
      >
        <Meta
          title={product.product_name}
          description={
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ font: "18px", color: "green" }}>
                  price: {rupee} {formatCurrency(product.offer_price)}
                </p>
                <p
                  style={{
                    font: "18px",
                    color: "red",
                    textDecoration: "line-through",
                  }}
                >
                  {rupee} {formatCurrency(product.maximum_retail_price)}
                </p>
              </div>
            </>
          }
        />
      </Card>
      <Modal
        title="Product Preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Carousel autoplay>
          {product.image_urls.map((url, index) => (
            <div key={index}>
              <img
                src={url}
                alt={`product-preview-${index}`}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </>
  );
};

export default ProductCard;
