// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  margin: auto;
  width: 90%;
  padding: 10px;
  margin-top: 100px;
  position: relative;
}

.main-container-mobile {
  margin: auto;
  width: 99%;
  padding: 10px;
  margin-top: 100px;
  position: relative;
}

.slide-container {
  margin: auto;
  width: 95%;
  padding: 10px;
  margin-top: 100px;
  position: relative;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/app_view.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".main-container {\n  margin: auto;\n  width: 90%;\n  padding: 10px;\n  margin-top: 100px;\n  position: relative;\n}\n\n.main-container-mobile {\n  margin: auto;\n  width: 99%;\n  padding: 10px;\n  margin-top: 100px;\n  position: relative;\n}\n\n.slide-container {\n  margin: auto;\n  width: 95%;\n  padding: 10px;\n  margin-top: 100px;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
