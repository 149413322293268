import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ProductContainer from "../ProductDisplay/ProductContainer";
import { Pagination, Row, Col, Space } from "antd";
import { Stack } from "@mui/joy";
import Product from "../ProductDisplay/Product";
import "../../../assets/css/app_view.css";
const domain = process.env.REACT_APP_DOMAIN;

const ProductPage = ({ cashback, discount, newProducts, vendor_id }) => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchAllProducts();
  }, [currentPage, pageSize, cashback, discount, newProducts]);

  const fetchAllProducts = async () => {
    try {
      let url = "";
      if (domain === "hlooms")
        url = `${process.env.REACT_APP_API_URL}api/v1/products/all/hlooms?page=${currentPage}&pageSize=${pageSize}`;
      else if (domain === "neonyte")
        url = `${process.env.REACT_APP_API_URL}api/v1/products/all?page=${currentPage}&pageSize=${pageSize}`;

      if (cashback) url += "&cashback=true";
      if (discount) url += "&discount=true";
      if (newProducts) url += "&new=true";

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Error fetching products");
      }
      const data = await response.json();
      setProducts(data.products);
      setTotalItems(data.totalItems);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedProducts = products.slice(startIndex, endIndex);

  return (
    <>
      <div className={isMobile ? "main-container-mobile" : "main-container"}>
        <Space direction="vertical" size={12}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Stack spacing={1} direction={"row"} alignItems={"left"}>
                <Row gutter={[16, 16]}>
                  {paginatedProducts.map((product, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6}>
                      <Product input={product} cashback={cashback} discount={discount} />
                    </Col>
                  ))}
                </Row>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24}>
              <section className="products">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  total={totalItems}
                />
              </section>
            </Col>
          </Row>
        </Space>
      </div>
    </>
  );
};

export default ProductPage;
