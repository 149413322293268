// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  background-color: #fdece8;
  padding: 20px;
}

.footer-logo {
  height: 50px;
}

.footer-title {
  color: #e75627;
}

.footer-link {
  color: #808080;
}

.footer-list {
  list-style-type: none;
  padding: 0;
}

.footer-text {
  color: #808080;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #001529;
  color: white;
  text-align: center;
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/layout-components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".footer-container {\n  background-color: #fdece8;\n  padding: 20px;\n}\n\n.footer-logo {\n  height: 50px;\n}\n\n.footer-title {\n  color: #e75627;\n}\n\n.footer-link {\n  color: #808080;\n}\n\n.footer-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.footer-text {\n  color: #808080;\n}\n\n.footer {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  background-color: #001529;\n  color: white;\n  text-align: center;\n  padding: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
