import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Menu,
  Modal,
  Button,
  Drawer,
  Badge,
} from "antd";
import {
  ShoppingCartOutlined,
  UserOutlined,
  MenuOutlined,
  LogoutOutlined,
  HeartFilled,
  HomeFilled,
  SearchOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import GeoReverseCoding from "./GeoReverseCoding";
import hlooms from "./hlooms.json";
import neonyte from "./neonyte.json";
import "./Header.css";
import SearchBar from "./SearchBar";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { signInSuccess } from "store/slices/authSlice";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { v4 as uuidv4 } from "uuid"; // Import UUID
import axios from "axios"; // Import axios
import { setCartCount } from "store/slices/cartSlice";
import store from "store"; // Import store
import { AUTH_TOKEN, PARSED_TOKEN } from "constants/AuthConstant"; // Import AUTH_TOKEN and PARSED_TOKEN
import MenuContent from "../MenuContent";

const domain = process.env.REACT_APP_DOMAIN;

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [deliverToZipcode, setDeliverToZipcode] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false); // State to control search bar visibility
  const { keycloak, initialized } = useKeycloak();
  const cartCount = useSelector((state) => state.cart?.count || 0); // Get cart count from Redux state with default value
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (keycloak.authenticated) {
      console.log("Sending token", keycloak.token);
      dispatch(signInSuccess(keycloak.token));
    } else {
      let dummy_username = localStorage.getItem("dummy_username");
      if (!dummy_username) {
        dummy_username = "Guest" + uuidv4().slice(-12).toUpperCase();
        localStorage.setItem("dummy_username", dummy_username);
      }
    }
  }, [keycloak, dispatch]);

  const handleSignIn = () => {
    const dummy_username = localStorage.getItem("dummy_username");
    const username = keycloak.tokenParsed?.preferred_username;
    if (
      keycloak.authenticated &&
      dummy_username &&
      dummy_username !== username
    ) {
      axios
        .post(process.env.REACT_APP_API_URL + "api/v1/cart/replaceUsername", {
          old_username: dummy_username,
          new_username: username,
        })
        .then(() => {
          localStorage.removeItem("dummy_username");
        })
        .catch((error) => {
          console.error("Error updating username in the backend:", error);
        });
    }
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      handleSignIn();
      // Fetch cart count on initial load
      const fetchCartCount = async () => {
        const username = keycloak.tokenParsed?.preferred_username;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/cart/user/${username}`
        );
        console.log("Cart count response:", response.data.count);
        dispatch(setCartCount(response.data.count));
      };
      fetchCartCount();
    }
  }, [keycloak.authenticated, dispatch]);

  useEffect(() => {
    // This effect will run whenever cartCount changes
    console.log("Cart count updated:", cartCount);
  }, [dispatch, cartCount]);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      console.log("state:", state);
      setCartCount(state.cart ? state.cart?.count : 0);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSignInClick = () => {
    keycloak.login();
  };

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(PARSED_TOKEN);
    keycloak.clearToken();
    const dummy_usename = "Guest" + uuidv4().slice(-12).toUpperCase();
    localStorage.setItem("dummy_username", dummy_usename);
    keycloak.logout();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  let menuData = {};
  if (domain === "hlooms") menuData = hlooms;
  else if (domain === "neonyte") menuData = neonyte;

  return (
    <div className="header-container">
      <Row gutter={16} align="middle">
        <Col xs={4} md={1} style={{ textAlign: "left" }}>
          {keycloak.authenticated && (
            <MenuOutlined className="header-icon" onClick={showDrawer} />
          )}
        </Col>
        <Col xs={4} md={1} style={{ textAlign: "left" }}>


          {domain === "neonyte" && (
            <EnvironmentOutlined
              className="header-icon"
              onClick={showModal}
              style={{ fontSize: "20px" }}
            />
          )}
          {deliverToZipcode && (
            <span >{deliverToZipcode}</span>
          )}
        </Col>

        <Col xs={keycloak.authenticated ? 18 : 20} md={6}>
          {showSearchBar ? (
            <SearchBar setSearchResults={setSearchResults} />
          ) : (
            <SearchOutlined className="header-icon" onClick={toggleSearchBar} />
          )}
        </Col>

        <Col xs={24} md={8} style={{ textAlign: "center" }}>
          <img
            src={`https://fileserver.neonyte.com/${domain}/logo/logo.jpg`}
            alt="Company Logo"
            className="header-logo"
            onClick={() => navigate("/")} // Navigate to homepage on click
            style={{ cursor: "pointer" }} // Add cursor pointer
          />
        </Col>

        <Col xs={24} md={8} style={{ textAlign: "right" }}>
          <Link to="/">
            <HomeFilled className="header-icon" />
          </Link>
          <Link to="/favorites">
            <HeartFilled className="header-icon" />
          </Link>
          <Link to="/cart">
            <Badge count={cartCount} offset={[0, 0]} showZero>
              <ShoppingCartOutlined className="header-icon" />
            </Badge>
          </Link>
          {keycloak.authenticated ? (
            <>
              <Button
                type="primary"
                icon={<LogoutOutlined />}
                className="header-login-button"
                onClick={handleLogout}
              >
                <span className="header-username">Logout</span>
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              icon={<UserOutlined />}
              className="header-login-button"
              onClick={handleSignInClick}
            >
              Login
            </Button>
          )}
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={24}
          md={24}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Menu
            mode="horizontal"
            className="header-menu"
            disabledOverflow="true"
          >
            {menuData.categories.map((category) =>
              category.subcategories && category.subcategories.length > 0 ? (
                <Menu.SubMenu key={category.name} title={category.name}>
                  {category.subcategories.map((subcategory) => (
                    <Menu.Item key={subcategory.name}>
                      <Link to={subcategory.link}>{subcategory.name}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={category.name}>
                  <Link to={category.link}>{category.name}</Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </Col>
      </Row>
      {keycloak.authenticated && (
        <Drawer
          title="Menu"
          placement="left"
          onClose={closeDrawer}
          visible={isDrawerOpen}
        >
          <MenuContent type={NAV_TYPE_SIDE} /> {/* Add MenuContent component */}
        </Drawer>
      )}
      <Modal
        title="GeoReverseCoding"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <GeoReverseCoding
          deliverToZipcode={deliverToZipcode}
          setDeliverToZipcode={setDeliverToZipcode}
        />
      </Modal>
    </div>
  );
};

export default Header;
