import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Pagination } from "swiper/modules";
import { Card, CardMedia, CardActionArea, Button } from "@mui/material";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const domain = process.env.REACT_APP_DOMAIN;

const hloom_images = [
  {
    image: "1.jpg",
    content: "Festive Collection",
    subtitle: "A touch of tradition",
  },
  {
    image: "2.jpg",
    content: "Premium Collection",
    subtitle: "Impressive high-end brands",
  },
  {
    image: "3.jpg",
    content: "Plus Size Collection",
    subtitle: "Size that fits the curvy and gracious",
  },
  {
    image: "4.jpg",
    content: "New Arrivals",
    subtitle: "Stay with the trend!",
  },
  {
    image: "5.jpg",
    content: "Sarees",
    subtitle: "Beauty is sublime with sarees",
  },
];

const neonyte_images = [
  {
    image: "1.jpg",
    content: "Phones",
  },
  {
    image: "2.jpg",
    content: "Laptops",
  },
  {
    image: "3.jpg",
    content: "Clothing",
  },
  {
    image: "4.jpg",
    content: "Cameras",
  },
  {
    image: "5.jpg",
    content: "Appliances",
  },
  {
    image: "6.jpg",
    content: "Desktops",
  },
  {
    image: "7.jpg",
    content: "Printers",
  },
  {
    image: "8.jpg",
    content: "Air Conditioners",
  },
];

export const SwiperComponent = () => {
  let images = [];
  if (domain === "hlooms") images = hloom_images;
  else images = neonyte_images;

  return (
    <>
      <div style={{ maxWidth: "1500px", justifyContent: "true" }}>
        <Swiper
          pagination={{
            clickable: true,
          }}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={30}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          height="300px"
          slidesPerGroupSkip={1} // Ensure this property is defined
        >
          {images.map((item, index) => (
            <SwiperSlide key={index}>
              <Card>
                <CardActionArea to={`${APP_PREFIX_PATH}/dashboards/products`}>
                  <div style={{ position: "center" }}>
                    <CardMedia
                      style={{
                        height: "300px",
                        borderRadius: "20px",
                      }}
                      component="img"
                      image={`${process.env.REACT_APP_FILESERVER}${domain}/images/slider/${item.image}`}
                      title={item.content}
                      alt={item.content}
                    />
                    <div
                      style={{
                        position: "absolute",
                        color: "#f48e6f",
                        backgroundColor: "#FFFFFF",
                        top: 100,
                        fontSize: "45px",
                        right: 20,
                        textAlign: "right",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                      {item.content}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        color: "#FFFFFF",
                        top: 160,
                        fontSize: "24px",
                        right: 20,
                        textAlign: "right",
                      }}
                    >
                      {" "}
                      {item.subtitle}
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#f48e6f",
                        position: "absolute",
                        top: 200,
                        fontSize: "14px",
                        right: 20,
                        textAlign: "right",
                      }}
                    >
                      Shop Now
                    </Button>
                  </div>
                </CardActionArea>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default SwiperComponent;
