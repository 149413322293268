import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { PARSED_TOKEN } from "constants/AuthConstant";
import axios from "axios";

const AddressList = ({ selectShippingAddress }) => {
  const [shippingAddressList, setShippingAddressList] = useState([]);
  const [shippingAddressDict, setShippingAddressDict] = useState([]);
  const parsed_token = JSON.parse(localStorage.getItem(PARSED_TOKEN));
  const username = parsed_token?.preferred_username
    ? parsed_token?.preferred_username
    : localStorage.getItem("dummy_username");

  const fetchShippingAddress = async (value) => {
    const url = process.env.REACT_APP_API_URL + `api/v1/address/${username}`;
    try {
      await axios.get(url).then((response) => {
        const data = response.data;
        let shipping_address = [];
        setShippingAddressDict(data);
        data.forEach((value) => {
          console.log("value", value);
          shipping_address.push({
            id: value.pickup_location,
            value: value.pickup_location,
            label: parsed_token
              ? `${value.address_nickname} - ${value.line1_address}, ${value.line2_address}, ${value.city}, ${value.state}, ${value.zipcode}`
              : `${value.line1_address}, ${value.line2_address}, ${value.city}, ${value.state}, ${value.zipcode}`,
          });
        });
        setShippingAddressList(shipping_address);
      });
    } catch {
      console.log("Error fetching addresses");
    }
  };
  const { Option } = Select;

  const deleteOption = async (value) => {
    console.log(value);
    setShippingAddressList(
      shippingAddressList.filter((item) => {
        return item.value !== value;
      })
    );
    const url = process.env.REACT_APP_API_URL + `api/v1/address/${value}`;
    await axios.delete(url);
    console.log("deleted", value);
  };

  useEffect(() => {
    fetchShippingAddress();
  }, []);

  return (
    <>
      <Select
        optionLabelProp="label"
        style={{
          width: "100%",
        }}
        onClick={fetchShippingAddress}
        onChange={(id) => selectShippingAddress(id, shippingAddressDict)}
      >
        {shippingAddressList !== undefined &&
          shippingAddressList.map((task) => {
            return (
              <Option key={task.id} value={task.value} label={task.label}>
                <span>{task.label}</span>
                <span style={{ float: "right" }}>
                  <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteOption(task.value);
                    }}
                  />
                </span>
              </Option>
            );
          })}
      </Select>
    </>
  );
};
export default AddressList;
