import React, { useEffect } from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import { useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { signInSuccess } from "../store/slices/authSlice";
import PublicRoutes from "./PublicRoutes";
import VendorPage from "views/vendor/VendorPage";
import ProductPage from "views/product/ProductPage";
import Home from "../components/layout-components/Home/Home";
import SearchResults from "components/layout-components/Search/SearchResults";
import TermsConditionsPolicy from "components/layout-components/Markdown/TermsConditionsPolicy";
import ReturnPolicy from "components/layout-components/Markdown/ReturnPolicy";
import PrivacyPolicy from "components/layout-components/Markdown/PrivacyPolicy";
import ShippingPolicy from "components/layout-components/Markdown/ShippingPolicy";
import AboutUs from "components/layout-components/About/AboutUs";
import ContactUs from "components/layout-components/Contact/ContactUs";
import Favorites from "components/layout-components/Favorites/Favorites"; // Import Favorites
import ShoppingCart from "components/layout-components/ShoppingCart"; // Import ShoppingCart
import ThankYouPage from "views/thank-you/ThankYouPage"; // Import ThankYouPage
import { v4 as uuidv4 } from "uuid"; // Import UUID
import Categories from "components/layout-components/Categories/Categories";
const Routes = () => {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!keycloak.authenticated) {
      dispatch(signInSuccess(null));
      let dummy_username = localStorage.getItem("dummy_username");
      if (!dummy_username) {
        dummy_username = "Guest" + uuidv4().slice(-12).toUpperCase();
        localStorage.setItem("dummy_username", dummy_username);
      }
    }
  }, [keycloak, dispatch]);

  return (
    <RouterRoutes>
      <Route path="/" element={<PublicRoute />}>
        <Route index element={<Home />} />
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <AppRoute
                routeKey={route.key}
                component={route.component}
                {...route.meta}
              />
            }
          />
        ))}
        <Route path="/category" element={<Categories />} />
        <Route path="vendor/:vendor_id" element={<VendorPage />} />
        <Route path="product/:product_id" element={<ProductPage />} />
        <Route path="/ReturnPolicy" element={<ReturnPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsConditionsPolicy />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/favorites" element={<Favorites />} />{" "}
        {/* Add Favorites route */}
        <Route path="/cart" element={<ShoppingCart />} />{" "}
        {/* Add ShoppingCart route */}
        <Route path="/thankyou" element={<ThankYouPage />} />{" "}
        {/* Add ThankYouPage route */}
      </Route>
      <Route path="/" element={<ProtectedRoute />}>
        {protectedRoutes.map((route, index) => (
          <Route
            key={route.key + index}
            path={route.path}
            element={
              <AppRoute
                routeKey={route.key}
                component={route.component}
                {...route.meta}
              />
            }
          />
        ))}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
