import Product from "./Product";
import { Row, Col, Stack } from "antd";

const ProductContainer = (props) => {
  const cashback = props.cashback;
  const discount = props.discount;
  const products = props.products;


  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[16, 16]}>
            {products.map((product, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Product input={product} cashback={cashback} discount={discount} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProductContainer;
