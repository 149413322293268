import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import MainLayout from "layouts/MainLayout";
import { useKeycloak } from "@react-keycloak/web";

const ProtectedRoute = () => {
  const { keycloak } = useKeycloak();

  const location = useLocation();
  if (!keycloak.authenticated) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export default ProtectedRoute;
