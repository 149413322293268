import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Pagination } from "antd";
import ProductDisplay from "../ProductDisplay";

const domain = process.env.REACT_APP_DOMAIN;

const SearchResults = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const pageSize = 12;

  const urlSearchParams = new URLSearchParams(location.search);
  const primary = urlSearchParams.get("primary");
  const secondary = urlSearchParams.get("secondary");
  const tertiary = urlSearchParams.get("tertiary");
  const minPrice = urlSearchParams.get("minPrice");
  const maxPrice = urlSearchParams.get("maxPrice");
  const sort = urlSearchParams.get("sort");
  const brand = urlSearchParams.get("brand");
  const rating = urlSearchParams.get("rating");
  const search = urlSearchParams.get("search");

  const buildQueryParams = () => {
    const params = new URLSearchParams();
    if (primary) params.append("primary", primary);
    if (secondary) params.append("secondary", secondary);
    if (tertiary) params.append("tertiary", tertiary);
    if (minPrice) params.append("minPrice", minPrice);
    if (maxPrice) params.append("maxPrice", maxPrice);
    if (sort) params.append("sort", sort);
    if (brand) params.append("brand", brand);
    if (rating) params.append("rating", rating);
    if (search) params.append("search", search);
    return params.toString();
  };

  const fetchSearchResults = async (query) => {
    // Replace with actual API call

    const queryParams = buildQueryParams();
    console.log("queryParams", queryParams);
    let url_str = "";
    if (domain === "neonyte") {
      url_str = `${process.env.REACT_APP_API_URL}api/v1/products/search?${queryParams}&page=${currentPage}&size=${pageSize}`;
    }
    else if (domain === "hlooms") {
      url_str = `${process.env.REACT_APP_API_URL}api/v1/products/search/hlooms?${queryParams}&page=${currentPage}&size=${pageSize}`;
    }
    let response = await fetch(url_str);
    const results = await response.json();
    setSearchResults(results.products);
  };

  useEffect(() => {
    if (query) {
      fetchSearchResults(query);
    }
  }, [location.search]); // Updated dependency array

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Fetch new results based on the new page
  };

  return (
    <Row justify="center" style={{ padding: "20px 0" }}>
      <Col xs={24} md={20}>
        <ProductDisplay products={searchResults} />
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={searchResults.length}
          onChange={handlePageChange}
          style={{ marginTop: "20px", textAlign: "center" }}
        />
      </Col>
    </Row>
  );
};

export default SearchResults;
