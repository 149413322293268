import {
  DashboardOutlined,
  AppstoreOutlined,
  FundOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  AccountBookOutlined,
  AimOutlined,
  ProductOutlined,
  ShoppingCartOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: FundOutlined,
    breadcrumb: false,
    isGroupTitle: true,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: AccountBookOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "dashboards-orders",
        path: `${APP_PREFIX_PATH}/dashboards/orders`,
        title: "sidenav.dashboard.orders",
        icon: GiftOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    isGroupTitle: true,
    submenu: [
      {
        key: "dashboards-userconsent",
        path: `${APP_PREFIX_PATH}/apps/userconsent`,
        title: "sidenav.apps.userconsent",
        icon: AimOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "dashboards-demograph",
        path: `${APP_PREFIX_PATH}/apps/demograph`,
        title: "sidenav.apps.demographic",
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-interest",
        path: `${APP_PREFIX_PATH}/apps/interest`,
        title: "sidenav.apps.interest",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const discoveryNavTree = [
  {
    key: "discovery",
    path: `${APP_PREFIX_PATH}/discovery`,
    title: "sidenav.discovery",
    icon: AppstoreOutlined,
    breadcrumb: false,
    isGroupTitle: true,
    submenu: [
      {
        key: "discovery-ai",
        path: `${APP_PREFIX_PATH}/discovery/ai`,
        title: "sidenav.discovery.ai",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...discoveryNavTree,
];

export default navigationConfig;
