import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

const VendorCard = ({ vendor_id }) => {
  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}api/v1/vendor/fetchVendorPortfolio/${vendor_id}`;
        console.log("url", url);
        const response = await axios.get(url);
        setVendor(response.data[0]);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };

    fetchVendorDetails();
  }, [vendor_id]);

  if (!vendor) {
    return <div>Loading...</div>;
  }
  console.log(
    process.env.REACT_APP_FILESERVER +
      `vendor/images/uploads/${vendor.vendor_avatar}`
  );
  return (
    <Card style={{ borderRadius: 20, padding: 20 }}>
      <CardMedia
        component="img"
        height="140"
        image={
          process.env.REACT_APP_FILESERVER +
          `vendor/images/uploads/${vendor.vendor_background_image}`
        }
        alt={`${vendor.vendor_name} background`}
      />
      <Box display="flex" alignItems="center" mt={2}>
        <img
          src={
            process.env.REACT_APP_FILESERVER +
            `vendor/images/uploads/${vendor.vendor_avatar}`
          }
          alt={`${vendor.vendor_brand_name} logo`}
          style={{
            width: 50,
            height: 50,
            borderRadius: "50%",
            marginRight: 16,
          }}
        />
        <CardContent>
          <Typography variant="h5">{vendor.vendor_brand_name}</Typography>
          <Typography variant="subtitle1">{vendor.vendor_tag_line}</Typography>
          <Typography variant="body2">{vendor.vendor_address}</Typography>
          <Typography variant="body2">{vendor.vendor_location_link}</Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

VendorCard.propTypes = {
  vendor_username: PropTypes.string.isRequired,
};

export default VendorCard;
