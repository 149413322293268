import React from "react";
import { Row, Col } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import "./Footer.css";

const domain = process.env.REACT_APP_DOMAIN;
const Footer = () => {
  return (
    <div className="footer-container">
      <Row gutter={16} class="footer">
        <Col xs={24} md={6} style={{ textAlign: "center" }}>
          <img
            src={`https://fileserver.neonyte.com/${domain}/logo/logo.jpg`}
            alt="Company Logo"
            className="footer-logo"
          />
          <ul className="footer-list">
            <li>
              <Typography variant="h6" className="footer-title">
                Retail Marketplace
              </Typography>
            </li>
            <li>
              <Typography variant="body" className="footer-text">
                36 Emami Nest 8th Main 16th Cross
              </Typography>
            </li>
            <li>
              <Typography variant="body" className="footer-text">
                Malleshwaram, Bengaluru - 560055
              </Typography>
            </li>
          </ul>
        </Col>
        <Col xs={24} md={6} style={{ textAlign: "center" }}>
          <Typography variant="h6" className="footer-title">
            Community
          </Typography>
          <ul className="footer-list">
            <li>
              <Typography
                component="a"
                href="https://www.facebook.com/neon.expr"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                <FacebookOutlined /> Facebook
              </Typography>
            </li>
            <li>
              <Typography
                component="a"
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                <InstagramOutlined /> Instagram
              </Typography>
            </li>
            <li>
              <Typography
                component="a"
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                <TwitterOutlined /> X
              </Typography>
            </li>
          </ul>
        </Col>
        <Col xs={24} md={6} style={{ textAlign: "center" }}>
          <Typography variant="h6" className="footer-title">
            Policies
          </Typography>
          <ul className="footer-list">
            <li>
              <Typography
                component={Link}
                to="/ReturnPolicy"
                className="footer-link"
              >
                Return Policy
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/ShippingPolicy"
                className="footer-link"
              >
                Shipping Policy
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/TermsAndConditions"
                className="footer-link"
              >
                Terms and Conditions
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/PrivacyPolicy"
                className="footer-link"
              >
                Privacy Policy
              </Typography>
            </li>
          </ul>
        </Col>
        <Col xs={24} md={6} style={{ textAlign: "center" }}>
          <Typography variant="h6" className="footer-title">
            Neonyte Marketplace
          </Typography>

          <ul className="footer-list">
            <li>
              <Typography
                component={Link}
                to="/ContactUs"
                className="footer-link"
              >
                Contact Us
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/AboutUs"
                className="footer-link"
              >
                About Us
              </Typography>
            </li>
          </ul>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Typography
          variant="body2"
          color="textSecondary"
          className="footer-text"
        >
          © 2023 Simulate Intelligence Private Limited
        </Typography>
      </Row>
    </div>
  );
};

export default Footer;
